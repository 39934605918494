import React, { useState, useEffect } from "react";
import memthotsSmallicon from "../../Assets/memthotsSmallicon.svg";
import styles from "./StickyFooter.module.css";

const StickyFooter = ({ trigger, loading }) => {
  const [isVisible, setIsVisible] = useState(false);

  const checkVisibility = () => {
    if (!loading) {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      if (scrollTop > 20 || windowHeight + scrollTop >= documentHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      checkVisibility();
    };
    window.addEventListener("scroll", handleScroll);
    checkVisibility();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    checkVisibility();
  }, [trigger, loading]);

  return (
    <a href="https://memshots.com/" target="_blank" rel="noopener noreferrer">
      <div
        className={`w-fit flex items-center gap-[5px] py-[5px] pl-[5px] pr-[8px] bg-white cursor-pointer rounded-[10px] fixed bottom-[16px] mx-auto right-0 left-0 text600 text-[14px] drop-shadow-lg z-30 
           ${styles.banner} ${isVisible ? styles.visible : styles.hidden}`}
      >
        <img src={memthotsSmallicon} alt="Memshots Icon" />
        <p>Made in memshots.com</p>
      </div>
    </a>
  );
};

export default StickyFooter;
