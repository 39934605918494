import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import backMobile from "../Assets/Images/studioBackMobile.svg";
import backWeb from "../Assets/Images/studioBackWeb.svg";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import styles from "./Css/StudioWorkDetailPage.module.css";
import ImageGallery from "../Components/ImageGallery/ImageGallery";
import Footer from "../Components/Footer";
import StickyFooter from "../Components/StickyFooter/StickyFooter";
import Loading from "../Assets/Animation/Loading";

export default function StudioWorkDetailPage() {
  const [studioData, setStudioData] = useState({});
  const [showCaseData, setShowcaseData] = useState([]);
  const [paginatedData, setPagenatedData] = useState([]);
  const [itemsCount, setItemsCount] = useState(8);
  const [hasMore, setHasMore] = useState(true);
  const [dataloading, setDataLoading] = useState(false);
  const { index } = useParams();
  let hostname = window.location.hostname;
  const parts = hostname?.split(".");
  const studio =
    parts?.length > 2 ? parts?.slice(0, parts?.length - 2).join(".") : null;
  const navigate = useNavigate();

  const observer = useRef();
  const lastElementRef = useCallback((node) => {
    if (dataloading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setItemsCount((prev) => prev + 8);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    getStudios();
  }, []);

  useEffect(() => {
    if (!isEmpty(studioData)) {
      let showcaseFilter =
        studioData && studioData?.showcase && studioData?.showcase[index];
      setShowcaseData(showcaseFilter);
      setPagenatedData(showcaseFilter?.media?.slice(0, itemsCount));
    }
  }, [studioData]);

  useEffect(() => {
    if (!isEmpty(showCaseData?.media)) {
      setPagenatedData(showCaseData?.media?.slice(0, itemsCount));
      if (itemsCount >= showCaseData?.media?.length) {
        setHasMore(false);
      }
    }
  }, [itemsCount, showCaseData?.media]);

  const backIcon = (innerWidth) => {
    if (innerWidth <= 768) {
      return backMobile;
    } else {
      return backWeb;
    }
  };

  const getStudios = async () => {
    try {
      setDataLoading(true);
      const data = await axios.get(
        `https://storage.googleapis.com/studio_portfolio/${studio}/portfolio.json`
      );
      setStudioData(data?.data);
      setDataLoading(false);
    } catch (err) {
      console.log(err);
      setDataLoading(false);
    }
  };
  return (
    <>
      {dataloading && <Loading />}
      <div className="pb-[60px]">
        <div className="relative">
          <img
            src={showCaseData?.coverImage}
            alt=""
            className="md:w-full md:h-[285px] w-full h-[196px] object-cover rounded-b-[8px] md:rounded-b-[2px]"
          />
          <div
            className="absolute inset-0 w-full h-full z-10 rounded-b-[8px] md:rounded-b-[2px]"
            style={{
              background:
                "linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%)",
            }}
          />
          <div className="absolute inset-0 w-full h-full flex justify-between gap-[16px] text-center px-[22px] md:px-[72px] pb-[10px] md:pb-[16px] pt-[16px] md:pt-[60px] z-20">
            <div className="cursor-pointer">
              <img
                src={backIcon(window.innerWidth)}
                alt=""
                onClick={() => {
                  navigate("/");
                }}
              />
            </div>
            <div className="text-white w-full h-full flex flex-col justify-center">
              <p
                className={`${styles.dmSerifFont} mb-[4px] md:mb-[6px]  text-[16px] md:text-[22px] leading-[22px] md:leading-[30px]`}
              >
                {showCaseData?.title}
              </p>
              <p
                className={`${styles.elsieFont}  text-[8px] md:text-[12px] leading-[11px] md:leading-[17px]`}
              >
                {showCaseData?.subtitle}
              </p>
            </div>
            <div className="min-w-[32px]" />
          </div>
        </div>
        <div className="max-w-[1440px] mx-auto mt-[16px] sm:mt-[60px] px-[8px] md:px-[70px]">
          <ImageGallery
            data={paginatedData}
            reference={lastElementRef}
            hasMore={hasMore}
            setItemsCount={setItemsCount}
          />
        </div>
      </div>
      <StickyFooter loading={dataloading} />
    </>
  );
}
