import React, { useState, useEffect } from 'react';

const Toast = ({ messages, onClose }) => {
  const [toastQueue, setToastQueue] = useState([]);

  useEffect(() => {
    if (messages.length > 0) {
      setToastQueue((prevQueue) => [...prevQueue, ...messages]);
    }
  }, [messages]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (toastQueue.length > 0) {
        setToastQueue((prevQueue) => prevQueue.slice(1));
        onClose();
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [toastQueue, onClose]);

  const handleToastClose = () => {
    setToastQueue((prevQueue) => prevQueue.slice(1));
    onClose();
  };

  return (
    <div className="fixed top-0 right-0 p-5 z-50">
      {toastQueue.length > 0 && (
        <div className="flex flex-col space-y-1">
          {toastQueue.slice(0, 5).map((message, index) => (
            <div
              key={index}
              className="bg-gray-800 text-white p-2 rounded-md shadow-md pointer-events-auto"
              style={{ width: '500px', height: '100px' }}
            >
              <p>{message}</p>
              <button
                onClick={handleToastClose}
                className="mt-1 bg-white text-gray-800 px-2 py-1 rounded-md"
              >
                Close
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Toast;
