import React, { useState } from "react";
import NavbarNew from "../Components/NavbarNew";
import styles from "./Css/CouponRedeemPage.module.css";
import axios from "axios";
import logoMemshots from '../Assets/logoMemshots.svg'

export default function CouponRedeemPage() {
  const [registerFormValues, setRegisterFormValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({
    show: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRegisterFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const successMessage = {
    title: "Thank you for registering.",
    description: " You will be receiving your coupon code shortly.",
    description2: "The coupon code has been sent to your Email as registered!",
  };

  const error = {
    title: "Email Already exists",
    description: "Please retry",
  };

  const handleSubmit = async (type) => {
    try {
      const validationErrors = validateForm(type);
      if (Object.keys(validationErrors).length === 0) {
        setLoading(true);
        let url = `https://asia-south1-shootmates.cloudfunctions.net/shootmatesPayments/api/shootmates/reedemcoupon/registerReedemCode`;
        let config = {
          method: "post",
          url: url,
          data: {
            ...registerFormValues,
            prefix: "AKPA",
          },
        };
        const response = await axios(config);
        if (response.status === 200) {
          setMessage((prevState) => ({
            ...prevState,
            show: true,
            content: successMessage,
          }));
          setLoading(false);
        } else {
          setMessage((prevState) => ({
            ...prevState,
            show: true,
            content: error,
            type: "error",
          }));
          setLoading(false);
        }
      } else {
        setLoading(false);
        setErrors(validationErrors);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const validateForm = () => {
    let errors = {};
    const phoneNumberPattern = /^[6-9]\d{9}$/gi;
    if (!registerFormValues.organisationName) {
      errors.organisationName = "Please enter your organization name.";
    }
    if (registerFormValues.phone) {
      if (!phoneNumberPattern.test(registerFormValues.phone)) {
        errors.phone = "Please enter a valid number.";
      }
    }
    if (!registerFormValues.email) {
      errors.email = "Please enter a valid email address.";
    }
    if (registerFormValues.email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(registerFormValues.email)) {
        errors.email = "Please enter a valid email address.";
      }
    }
    return errors;
  };

  return (
    <div className="w-full h-full">
      {/* <NavbarNew /> */}
      <div className="border-b-[1px] border-[#8B34FD1A] px-[5.26%] lg:px-[120px] pt-[30px] pb-[12px] lg:pb-[20px]  flex items-center justify-between  bg-white w-full sticky top-0 z-50">
        <div className="flex gap-[16px]">
          {/* <div className="mobileOnly">
          <Hamburger />
        </div> */}
          <div
            className="text-[#161616] hover:opacity-[0.8]"
          >
            {/* <p className="font-bold text-[20px] xl:text-[22px]">
            SHOOT<span className="font-normal">MATES</span>
          </p> */}
            <img src={logoMemshots} alt="" className="w-auto h-[54px] " />
          </div>
        </div>
      </div>
      {message?.show ? (
        <>
          <div className="text-[14px] leading-[19px] text-[#000]  font-medium  px-[17px] text-center flex flex-col justify-center items-center h-[70vh] ">
            <p className={`${styles.groteskBold} mb-[8px]`}>
              {message?.content?.title}
            </p>
            {message?.content?.description && (
              <p className="mb-[16px]">{message?.content?.description}</p>
            )}
            {message?.content?.description2 && (
              <p className="mb-[24px] ">{message?.content?.description2}</p>
            )}
            {message?.type == "error" && (
              <button
                onClick={() => {
                  setMessage({
                    show: false,
                  });
                  setRegisterFormValues({});
                }}
                className={`${styles.uploadPhotoButton} w-fit`}
              >
                Retry
              </button>
            )}
          </div>
        </>
      ) : (
        <div className="w-full h-full min-h-[70vh] px-[5.26%] lg:px-[8.33%]  mb-[60px] bg-white">
          <div className="w-full h-full flex flex-col items-center text-center">
            <p className="font_semibold_32 text-[#000000] mt-[16px] lg:mt-[24px] max-w-[595px]">
              Please fill out the following information to register for coupon.
            </p>
            <p className="font_medium_16_14 text-[#161616] mt-[16px] lg:mt-[24px] max-w-[563px]"></p>
          </div>
          <div className="flex flex-col items-center">
            <div className="w-full my-[30px]">
              <p className={`${styles.groteskBold}`}>Organisation Name</p>
              <input
                type="text"
                name="organisationName"
                className="py-[16px] border-[1px]  border-[#47054099] rounded-[12px] bg-[#FFF] w-full text-center text-[14px] leading-[22px] text-[#000]"
                placeholder="Please enter your organisation name*"
                onChange={handleInputChange}
                value={registerFormValues?.organisationName}
                style={{
                  border: errors?.organisationName && "1px solid #fc1f1f99",
                }}
              />
              {errors.organisationName && (
                <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                  {errors.organisationName}
                </p>
              )}
            </div>

            <div className="w-full mb-[30px]">
              <p className={`${styles.groteskBold}`}>Email</p>
              <input
                type="text"
                name="email"
                className="py-[16px] border-[1px]  border-[#47054099] rounded-[12px] bg-[#FFF] w-full text-center text-[14px] leading-[22px] text-[#000]"
                placeholder="Please enter your email address*"
                onChange={handleInputChange}
                value={registerFormValues?.email}
                style={{ border: errors?.email && "1px solid #fc1f1f99" }}
              />
              {errors.email && (
                <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                  {errors.email}
                </p>
              )}
            </div>
            <div className="w-full mb-[30px]">
              <p className={`${styles.groteskBold}`}>Mobile Number</p>
              <input
                type="text"
                name="phone"
                className="py-[16px] border-[1px]  border-[#47054099] rounded-[12px] bg-[#FFF] w-full text-center text-[14px] leading-[22px] text-[#000]"
                placeholder="Please enter your mobile number"
                onChange={handleInputChange}
                value={registerFormValues?.phone}
                style={{ border: errors?.phone && "1px solid #fc1f1f99" }}
              />
              {errors.phone && (
                <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                  {errors.phone}
                </p>
              )}
            </div>
            {
              loading ?
                <button
                  className={`${styles.uploadPhotoButton} w-fit mt-[16px]`}
                >
                  Registering..
                </button> :

                <button
                  onClick={() => {
                    handleSubmit();
                  }}
                  className={`${styles.uploadPhotoButton} w-fit mt-[16px]`}
                >
                  Register
                </button>
            }

          </div>
        </div>
      )}
    </div>
  );
}
