import React, { useEffect, useState } from 'react'
import NavbarNew from '../Components/NavbarNew'
import Footer from '../Components/Footer'
import NavbarBottom from '../Components/NavbarBottom'
import axios from 'axios'
import playIcon from '../Assets/Images/playIcon.svg'
import VideoPopup from '../Components/VideoPopup'

function Tutorials() {
    const [tutorialsData, setTutorialsData] = useState({})
    const [fliterSelected, setFilterSelected] = useState("Videography")
    const [popup, setPopup] = useState(false)
    const [videoSelected, setVideoSelected] = useState("")

    useEffect(() => {
        getTutorials("Videography")
        window.scroll(0, 0)
    }, [])

    const getTutorials = async (filterValue) => {
        try {
            const data = await axios.get(`/Json/Tutorials/${filterValue}.json`)
            setTutorialsData(data?.data)
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div>
            {popup && <VideoPopup setPopup={setPopup} urlSelected={videoSelected} />}
            <NavbarNew />
            <div className='w-full h-full min-h-[70vh] px-[5.26%] xl:px-[8.33%] mb-[60px]'>
                <div className='pt-[12px] w-full  pb-[16px] lg:pb-[24px] flex items-center lg:justify-center  gap-[16px] mx-auto overflow-y-hidden overflow-x-auto no-scrollbar sticky top-[83px] lg:top-[97px] z-40  bg-[#FFF]'>
                    <button
                        onClick={() => {
                            setFilterSelected("Videography")
                            getTutorials("Videography")
                        }}
                        className={` ${fliterSelected === 'Videography' ? "bg-[#8B34FD] text-[#FFFFFF]" : "bg-[#F8F8F8] text-[#161616]"} filterChip  lg:hover:bg-[#8B34FD]  lg:hover:text-[#FFFFFF] font_medium_12`}
                    >
                        Videography
                    </button>
                    <button
                        onClick={() => {
                            setFilterSelected("Photography")
                            getTutorials("Photography")
                        }}
                        className={` ${fliterSelected === 'Photography' ? "bg-[#8B34FD] text-[#FFFFFF]" : "bg-[#F8F8F8] text-[#161616]"} filterChip  lg:hover:bg-[#8B34FD]  lg:hover:text-[#FFFFFF] font_medium_12`}
                    >
                        Photography
                    </button>
                    <button
                        onClick={() => {
                            setFilterSelected("Editing")
                            getTutorials("Editing")
                        }}
                        className={` ${fliterSelected === 'Editing' ? "bg-[#8B34FD] text-[#FFFFFF]" : "bg-[#F8F8F8] text-[#161616]"} filterChip  lg:hover:bg-[#8B34FD]  lg:hover:text-[#FFFFFF] font_medium_12`}
                    >
                        Editing
                    </button>
                    <button
                        onClick={() => {
                            setFilterSelected("LatestTechnologies")
                            getTutorials("LatestTechnologies")
                        }}
                        className={` ${fliterSelected === 'LatestTechnologies' ? "bg-[#8B34FD] text-[#FFFFFF]" : "bg-[#F8F8F8] text-[#161616]"} filterChip  lg:hover:bg-[#8B34FD]  lg:hover:text-[#FFFFFF] font_medium_12`}
                    >
                        Latest Technologies
                    </button>
                </div>
                <div className="w-full h-full flex flex-col items-center text-center">
                    <p className="font_semibold_40_30 text-[#000] mt-[16px] lg:mt-[24px] max-w-[595px]">
                        We’ve got tutorials that's perfect for you.
                    </p>
                    <p className="font_medium_16_14 text-[#161616] mt-[16px] lg:mt-[24px] max-w-[563px]">
                        Tutorials for your personal and businesses at every stage of growth.
                    </p>
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-3 gap-[16px] mt-[24px] lg:mt-[32px]'>
                    {tutorialsData?.tutorials?.map((obj, i) => {
                        return (
                            <div
                                key={i}
                                className='relative cursor-pointer hover:opacity-[0.9]'
                                onClick={() => {
                                    setVideoSelected(obj?.videoUrl)
                                    setPopup(true)
                                }}>
                                <img src={obj?.thumbnail} alt="" className='aspect-video object-cover rounded-[12px]' />
                                <div className='absolute bottom-[10px] left-[16px] text-white'>
                                    <p className='font-semibold text-[14px] leading-[22px] tracking-[-0.28px]' >{obj?.title}</p>
                                    <p className='font-medium text-[13px] leading-[18px]  tracking-[-0.26px]'>{obj?.shortDescription}</p>
                                </div>
                                <img src={playIcon} alt="" className='absolute top-[50%] left-[50%] containerCenter' />
                            </div>
                        )
                    })}
                </div>
            </div>
            <Footer />
            <NavbarBottom />
        </div>
    )
}

export default Tutorials