import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { userAuthContext } from "./Context/UserAuthContext";

function ProtectedRoute(props) {
  const { Component } = props;
  const navigate = useNavigate();
  const { currentUser } = useContext(userAuthContext)
  useEffect(() => {
    if (!currentUser) {
      navigate(`/login`);
    }
  });

  return (
    <div>
      <Component />
    </div>
  );
}

export default ProtectedRoute;
