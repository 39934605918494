// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyBJIjwBkj3AB00mBoG9HjawknJH6VZbFE0",
  authDomain: "memshots-380011.firebaseapp.com",
  projectId: "memshots-380011",
  storageBucket: "memshots-380011.appspot.com",
  messagingSenderId: "952254678432",
  appId: "1:952254678432:web:3b411d81d5aabce4b510c5",
  measurementId: "G-SMDHZDVL6C"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig,"memshots");
export const faceStorage = getStorage(app, 'memshots_face_requests')

