import React from 'react'
import aiEventPlanners from '../../Assets/Images/aiEventPlanners.png'

function EventPlanners() {
    return (
        <div className='w-full h-full xl:px-[8.33%] mb-[120px] xl:mb-[140px]'>
            <div className='mobileOnly mb-[24px] px-[5.26%]'>
                <div className='w-fit mb-[16px]  px-[24px]  py-[10px] rounded-[50px] bg-[#C2F9E4] text-[#161616] font-medium text-[13px] leading-[18px] tracking-[-0.26px]'>Let’s bring it from here</div>
                <p className='mb-[16px] font-semibold text-[30px] tracking-[-1.5px] leading-[38px] text-[#161616]'>For Event Planners</p>
                <p className='text-[16px] tracking-[-0.32px] leading-[22px] text-[#161616]'>Shootmates is the new era of photography community, the best tools to discover. Connect with photographers and share creative skills</p>
            </div>
            <div className='flex flex-col-reverse xl:grid xl:grid-cols-6'>
                <div className='xl:col-span-4 flex flex-col justify-between'>
                    <div className='desktopOnly flex-col'>
                        <div className='w-fit xl:mb-[16px]  xl:px-[24px]  xl:py-[10px] rounded-[50px] bg-[#C2F9E4] text-[#161616] font-medium  xl:text-[16px]  xl:leading-[22px]  xl:tracking-[-0.32px]'>Let’s bring it from here</div>
                        <p className=' xl:mb-[16px] font-semibold  xl:text-[40px]  xl:tracking-[-2px]  xl:leading-[52px] text-[#161616]'>For Event Planners</p>
                        <p className='xl:max-w-[424px] text-[16px] tracking-[-0.32px] leading-[22px] text-[#161616]'>Shootmates is the new era of photography community, the best tools to discover. Connect with photographers and share creative skills</p>
                    </div>
                    <div className='flex flex-col gap-[40px] xl:grid xl:grid-cols-2 xl:gap-y-[54px] px-[5.26%] xl:px-0'>
                        <div>
                            <p className='mb-[16px] font-medium  text-[24px]  leading-[32px]  tracking-[-0.48px] text-[#161616]'>Instant photo sharing</p>
                            <p className='xl:max-w-[260px] mb-[16px] text-[13px] leading-[18px] tracking-[-0.26px] text-[#787878]'>Instant availability of photos increases photo sharing on social media</p>
                            <p className='text-[13px] leading-[18px] tracking-[-0.26px] text-[#161616] underline'>Try it now</p>
                        </div>
                        <div>
                            <p className='mb-[10px] font-medium  text-[24px]  leading-[32px]  tracking-[-0.48px] text-[#161616]'>Swift event creation</p>
                            <p className='xl:max-w-[260px] mb-[16px] text-[13px] leading-[18px] tracking-[-0.26px] text-[#787878]'>Create a dedicated themed events' page with schedule and sessions.</p>
                            <p className='text-[13px] leading-[18px] tracking-[-0.26px] text-[#161616] underline'>Try it now</p>
                        </div>
                        <div>
                            <p className='mb-[10px] font-medium  text-[24px]  leading-[32px]  tracking-[-0.48px] text-[#161616]'>Online Invitation</p>
                            <p className='xl:max-w-[260px] mb-[16px] text-[13px] leading-[18px] tracking-[-0.26px] text-[#787878]'>Effortlessly push your events to all wide. And invite them with no boundaries</p>
                            <p className='text-[13px] leading-[18px] tracking-[-0.26px] text-[#161616] underline'>Try it now</p>
                        </div>
                        <div>
                            <p className='mb-[10px] font-medium  text-[24px]  leading-[32px]  tracking-[-0.48px] text-[#161616]'>All in one dashboard</p>
                            <p className='xl:max-w-[260px] mb-[16px] text-[13px] leading-[18px] tracking-[-0.26px] text-[#787878]'>It is an event marketing tool, its your DAM, its a CRM, its an expense tracker. Its all in one.</p>
                            <p className='text-[13px] leading-[18px] tracking-[-0.26px] text-[#161616] underline'>Try it now</p>
                        </div>
                    </div>
                </div>
                <div className='sm:px-[5.26%] xl:px-0 xl:col-span-2 xl:pt-[12px] mb-[28px] xl:mb-0 flex justify-center sm:justify-start xl:justify-center'>
                    <img src={aiEventPlanners} alt="" />
                </div>
            </div>
        </div>
    )
}

export default EventPlanners