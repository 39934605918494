import React from "react";

function StudioReviews({ data }) {
  return (
    <div>
      {data?.map((obj) => (
        <div className="mb-[30px] flex gap-[10px]">
          <div className="w-[40px] h-[40px] rounded-full overflow-hidden shrink-0">
            <img
              src={obj?.icon}
              className="h-full w-full object-cover rounded-full"
            />
          </div>
          <div>
            <p className="mb-[6px] text600 text-[14px] md:text-[16px]">
              {obj?.title}
            </p>
            <pre className="text400 text-[14px] md:text-[16px] md:w-[80%]">
              {obj?.description}
            </pre>
          </div>
        </div>
      ))}
    </div>
  );
}

export default StudioReviews;
