import React, { useContext } from "react";
import { userAuthContext } from "../../Context/UserAuthContext";
import "./Login.css";
export default function Login() {
  const { googleSignIn, loading,currentUser,guestSignIn } = useContext(userAuthContext);
  return (
    <>
      <div className="login">
        <div className="container">
          <h2>Welcome back!</h2>
          <div className="fieldDetails">
          </div>
          {loading ? (
            <button>Loading</button>
          ) : (
            <>
            <button onClick={googleSignIn}>Sign in with Google</button>
            <button onClick={guestSignIn}>Sign as Guest</button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
