import React from 'react'

function Photographer() {
    return (
        <div className='w-full h-full px-[5.26%] xl:px-[8.33%] mb-[30px] xl:mb-[140px]'>
            <div className='w-fit mb-[16px] xl:mb-[30px]  px-[24px] py-[10px] rounded-[50px] bg-[#F3AFE4] text-[#161616] font-medium  text-[13px] xl:text-[16px]  leading-[18px] xl:leading-[22px]  tracking-[-0.26px] xl:tracking-[-0.32px]'>Are you a photographer ?</div>
            <p className='xl:max-w-[649px] mb-[16px] xl:mb-[24px] font-semibold  text-[30px] xl:text-[40px]  tracking-[-0.6px] xl:tracking-[-2px]  leading-[40px] xl:leading-[52px] text-[#161616]'>Everything you need to grow your photography business</p>
            <p className='xl:max-w-[535px] text-[16px] tracking-[-0.32px] leading-[22px] text-[#161616]'>Shootmates is the new era of photography community, the best tools to discover. Connect with photographers and share creative skills</p>
        </div>
    )
}

export default Photographer