import React from 'react'
import uploadanimation from './scanning.json'
import Lottie from 'react-lottie-player'
function FaceScanning() {
  return (

    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Lottie
        loop
        animationData={uploadanimation}
        play
        style={{
          width: 200,
          height: 200
        }}
      />
    </div>
  )
}

export default FaceScanning
