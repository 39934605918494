import { Route, Routes } from "react-router";
import "./App.css";
//import Home from "./Pages/Home";
//import ContactUs from "./Pages/ContactUs";
import { UserAuthContextProvider } from "./Context/UserAuthContext";
import StudioPortfolio from "./Pages/StudioPortfolio";
import AiPowered from "./Pages/AiPowered";
import Login from "./Pages/Login/Login";
import Jobs from "./Pages/Jobs";
import ProtectedRoute from "./ProtectedRoute";
import Pricing from "./Pages/Pricing";
import HomeNew from "./Pages/HomeNew";
import PricingCalculator from "./Pages/PricingCalculator";
import Tutorials from "./Pages/Tutorials";
import Community from "./Pages/Community";
import TandC from "./Pages/TandC";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Refund from "./Pages/Refund";
import Shipping from "./Pages/Shipping";
//import FaceDetectionDemo from "./Pages/FaceDetectionDemo";
//import FaceDetectionDemoUploadFace from "./Pages/FaceDetectionDemoUploadFace";
import PaymentThirdParty from "./Pages/PaymentThirdParty";
//import FacedectionUploader from "./Pages/FacedectionUploader";
//import FaceDetectionScanning from "./Pages/FaceDetectionScanning";
import FaceDetectionDemoLanding from "./Pages/FaceDetectionDemoLanding";
import FaceDetectionDemoTakeSelfie from "./Pages/FaceDetectionDemoTakeSelfie";
import FaceDetectionDemoPhotographer from "./Pages/FaceDetectionDemoPhotographer";
import faceDetectionDemoScanning from "./Pages/FaceDetectionDemoScanning";
import CouponRedeemPage from "./Pages/CouponRedeemPage";
import StudioWorkDetailPage from "./Pages/StudioWorkDetailPage";
function App() {
  const deployment = process.env.REACT_APP_DEPLOYMENT;

  return (
    <div className="App ">
      <UserAuthContextProvider>
        <Routes>
          {/* <Route path="/" element={<ContactUs />} /> */}
          {/* <Route path="/" element={<Home />} /> */}

          <Route path="/login" element={<Login />} />

          {deployment == "PORTFOLIO" ? (
            <>
              <Route path="/" element={<StudioPortfolio />} />
              <Route path="/:index" element={<StudioWorkDetailPage />} />
            </>
          ) : (
            <>
              <Route path="/" element={<HomeNew />} />
              <Route path="/ai" element={<AiPowered />} />
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route
                path="/pricingcalculator"
                element={<PricingCalculator />}
              />
              <Route path="/tutorials" element={<Tutorials />} />
              <Route path="/community" element={<Community />} />
              <Route path="/tandc" element={<TandC />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/refund" element={<Refund />} />
              <Route
                path="/aiphotosortingdemo"
                element={
                  <ProtectedRoute Component={FaceDetectionDemoLanding} />
                }
              />
              <Route
                path="/aiphotosortingdemo/:folderId"
                element={
                  <ProtectedRoute Component={FaceDetectionDemoTakeSelfie} />
                }
              />
              <Route
                path="/aiphotosortingdemo/:folderId/upload"
                element={
                  <ProtectedRoute Component={FaceDetectionDemoPhotographer} />
                }
              />
              <Route
                path="/aiphotosortingdemo/:folderId/scanning"
                element={
                  <ProtectedRoute Component={faceDetectionDemoScanning} />
                }
              />
              <Route path="/shipping" element={<Shipping />} />
              <Route
                path="/thirdpartyPayment"
                element={<PaymentThirdParty />}
              />
              <Route path="/redeemCoupon" element={<CouponRedeemPage />} />
            </>
          )}

          {/* <Route
            path="/facedetectiondemo"
            element={<ProtectedRoute Component={FaceDetectionDemo} />}
          /> */}
          {/* <Route
            path="/facedetectiondemo/:folderId"
            element={<ProtectedRoute Component={FaceDetectionDemoUploadFace} />}
          /> */}
          {/* <Route
            path="/facedetectiondemo/:folderId/upload"
            element={<ProtectedRoute Component={FacedectionUploader} />}
          /> */}
          {/* <Route
            path="/facedetectiondemo/:folderId/scanning"
            element={<ProtectedRoute Component={FaceDetectionScanning} />}
          /> */}
        </Routes>
      </UserAuthContextProvider>
    </div>
  );
}

export default App;
