import React from 'react';
import './Delete.css'
function ErrorPopup({ error, setError }) {

  return (
    <div className='deleteClient'>
      <div className='deleteClient-top'>
        <img src="" alt="" />
        <div className='deleteClient-top-text'>
          <p className='deleteClient-top-text1'>{error?.header}</p>
          <p className='deleteClient-top-text2'>{error?.errorMessage}</p>
        </div>
      </div>
      <div className='deleteClient-bottom'>
        <div className='deleteClient-button'>
          <button className='deleteClient-button1' onClick={() => {
            setError({ errorMessage: "", errorStatus: false })
          }}>Close</button>
        </div>
      </div>
    </div>
  );
}

export default ErrorPopup;
