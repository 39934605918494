import React, { useEffect, useRef, useState } from "react";
import NavbarNew from "../Components/NavbarNew";
import aiFaceDetection from "../Assets/Images/NewDesign/aiFaceDetection.png";
import aiMagicIcon from "../Assets/Images/aiMagicIcon.svg";
import signatureAlbum from "../Assets/Images/NewDesign/signatureAlbum.png";
import onlineInvitation from "../Assets/Images/NewDesign/onlineInvitation.png";
import desktopTool from "../Assets/Images/NewDesign/desktopTool.png";
import selectionTool from "../Assets/Images/NewDesign/selectionTool.png";
import shootmatesCRM from "../Assets/Images/NewDesign/shootmatesCRM.png";
import Footer from "../Components/Footer";
import { Link, useNavigate } from "react-router-dom";
import home from "../Assets/Images/BottomNavbarIcons/home.svg";
import NavbarBottom from "../Components/NavbarBottom";
import axios from "axios";
import VideoPopup from "../Components/VideoPopup";
import Events from "../Components/Home/Events";
import Clients from "../Components/Home/Clients";

function HomeNew() {
  const airef = useRef();
  const albumref = useRef();
  const invitationref = useRef();
  const desktoptoolref = useRef();
  const selectiontoolref = useRef();
  const crmref = useRef();
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [videoUrl, setVideoUrl] = useState({});
  const [urlSelected, setUrlSelected] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getVideoUrls();
  }, []);

  const scrollToaiRef = () => {
    airef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToalbumRef = () => {
    albumref.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToinvitationRef = () => {
    invitationref.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollTodesktoptoolRef = () => {
    desktoptoolref.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToselectiontoolRef = () => {
    selectiontoolref.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollTocrmRef = () => {
    crmref.current.scrollIntoView({ behavior: "smooth" });
  };

  const getVideoUrls = async () => {
    try {
      const response = await axios.get("/Json/ServicesVIdeosUrl.json");
      setVideoUrl(response?.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="w-full h-full">
      {popup && <VideoPopup setPopup={setPopup} urlSelected={urlSelected} />}

      <NavbarNew />

      <div className="w-full h-full min-h-[70vh] px-[5.26%] lg:px-[8.33%]  mb-[60px] bg-white">
        {/* filter chips */}

        <div className="pt-[20px] w-full  pb-[16px] lg:pb-[24px] flex items-center lg:justify-center  gap-[16px] mx-auto overflow-y-hidden overflow-x-auto no-scrollbar sticky top-[97px]  lg:top-[105px]  z-40  bg-[#FFF]">
          <button
            onClick={scrollToaiRef}
            className="filterChip bg-[#F8F8F8] hover:bg-[#8B34FD] text-[#161616] hover:text-[#FFFFFF] font_medium_12"
          >
            AI Photo Delivery
          </button>
          <button
            onClick={scrollToalbumRef}
            className="filterChip bg-[#F8F8F8] hover:bg-[#8B34FD] text-[#161616] hover:text-[#FFFFFF] font_medium_12"
          >
            Signature Album
          </button>
          {/* <button
            onClick={scrollToinvitationRef}
            className="filterChip bg-[#F8F8F8] hover:bg-[#8B34FD] text-[#161616] hover:text-[#FFFFFF] font_medium_12"
          >
            Online Invitation
          </button> */}
          <button
            onClick={scrollTodesktoptoolRef}
            className="filterChip bg-[#F8F8F8] hover:bg-[#8B34FD] text-[#161616] hover:text-[#FFFFFF] font_medium_12"
          >
            Desktop Tool
          </button>
          <button
            onClick={scrollToselectiontoolRef}
            className="filterChip bg-[#F8F8F8] hover:bg-[#8B34FD] text-[#161616] hover:text-[#FFFFFF] font_medium_12"
          >
            Photo Selection
          </button>
          <button
            onClick={scrollTocrmRef}
            className="filterChip bg-[#F8F8F8] hover:bg-[#8B34FD] text-[#161616] hover:text-[#FFFFFF] font_medium_12"
          >
            Memshots CRM
          </button>
        </div>

        {/* cover section */}

        <div className="w-full h-full flex flex-col items-center text-center">
          <p className="font_semibold_40_30 text-[#000] mt-[16px] lg:mt-[24px] max-w-[595px]">
            Everything you need to boost up your Studio Business.
          </p>
          <p className="font_medium_16_14 text-[#161616] mt-[16px] lg:mt-[24px] max-w-[563px]">
            Crafted for the discerning eye, our advanced boosting tools empowers
            photographers with unparalleled precision and creative prowess,
            transforming moments into timeless masterpieces.
          </p>
          <a
            href="https://calendly.com/shootmates/30min"
            target="_blank"
            rel="noreferrer"
          >
            <div className="px-[24px] py-[10px] rounded-[50px] bg-[#000000] mt-[24px] lg:mt-[30px] cursor-pointer hover:opacity-[0.8] ">
              <p className="font_medium_16_14 text-[#F6F6F6] ">
                Request a demo
              </p>
            </div>
          </a>
        </div>

        {/* section-1 */}

        <div
          ref={airef}
          className="w-full h-full mt-[60px] lg:mt-[88px] grid lg:grid-cols-2 lg:gap-[60px] gap-[30px] lg:px-[78px] "
        >
          <img
            src={aiFaceDetection}
            alt=""
            className="w-full h-full rounded-[12px] object-cover"
          />
          <div className="">
            <p className="font_medium_18 text-[#8B34FD] ">AI Photo Delivery</p>
            <p className="font_semibold_32 text-[#000000] mt-[8px]">
              Discover the magic of AI-powered galleries
            </p>
            <p className="font_normal_16 text-[#000000] mt-[20px]">
              Deliver photos with the best in world-class face recognition
              technology.
            </p>
            <div className="flex flex-col gap-[24px] lg:gap-[40px] mt-[30px] lg:mt-[38px]">
              <div className="flex gap-[12px] items-center">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 5.6L19 7L17.6 4.5L19 2L16.5 3.4L14 2L15.4 4.5L14 7L16.5 5.6ZM4.5 15.4L2 14L3.4 16.5L2 19L4.5 17.6L7 19L5.6 16.5L7 14L4.5 15.4ZM2 2L3.4 4.5L2 7L4.5 5.6L7 7L5.6 4.5L7 2L4.5 3.4L2 2ZM10.66 12.78L8.22 10.34L10.34 8.22L12.78 10.66L10.66 12.78ZM9.63 7.29L7.29 9.63C6.9 10 6.9 10.65 7.29 11.04L18.96 22.71C19.35 23.1 20 23.1 20.37 22.71L22.71 20.37C23.1 20 23.1 19.35 22.71 18.96L11.04 7.29C10.65 6.9 10 6.9 9.63 7.29Z"
                      fill="#9747FF"
                    />
                  </svg>
                </div>
                <p className="font_medium_13 text-[#161616]">
                  Users can pre-register their faces via a QR code to
                  automatically receive their pictures from the event via
                  WhatsApp and email.
                </p>
              </div>
              <div className="flex gap-[12px] items-center">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 5.6L19 7L17.6 4.5L19 2L16.5 3.4L14 2L15.4 4.5L14 7L16.5 5.6ZM4.5 15.4L2 14L3.4 16.5L2 19L4.5 17.6L7 19L5.6 16.5L7 14L4.5 15.4ZM2 2L3.4 4.5L2 7L4.5 5.6L7 7L5.6 4.5L7 2L4.5 3.4L2 2ZM10.66 12.78L8.22 10.34L10.34 8.22L12.78 10.66L10.66 12.78ZM9.63 7.29L7.29 9.63C6.9 10 6.9 10.65 7.29 11.04L18.96 22.71C19.35 23.1 20 23.1 20.37 22.71L22.71 20.37C23.1 20 23.1 19.35 22.71 18.96L11.04 7.29C10.65 6.9 10 6.9 9.63 7.29Z"
                      fill="#9747FF"
                    />
                  </svg>
                </div>
                <p className="font_medium_13 text-[#161616]">
                  Let your users find and download their high-resolution
                  pictures by simply taking a selfie.
                </p>
              </div>
              <div className="flex gap-[12px] items-center">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 5.6L19 7L17.6 4.5L19 2L16.5 3.4L14 2L15.4 4.5L14 7L16.5 5.6ZM4.5 15.4L2 14L3.4 16.5L2 19L4.5 17.6L7 19L5.6 16.5L7 14L4.5 15.4ZM2 2L3.4 4.5L2 7L4.5 5.6L7 7L5.6 4.5L7 2L4.5 3.4L2 2ZM10.66 12.78L8.22 10.34L10.34 8.22L12.78 10.66L10.66 12.78ZM9.63 7.29L7.29 9.63C6.9 10 6.9 10.65 7.29 11.04L18.96 22.71C19.35 23.1 20 23.1 20.37 22.71L22.71 20.37C23.1 20 23.1 19.35 22.71 18.96L11.04 7.29C10.65 6.9 10 6.9 9.63 7.29Z"
                      fill="#9747FF"
                    />
                  </svg>
                </div>
                <p className="font_medium_13 text-[#161616]">
                  Smartly identify and group photos of people to help you
                  quickly find photos visually.
                </p>
              </div>
            </div>
            <div
              className="flex gap-[24px] mt-[30px] lg:mt-[60px]"
            >
              <button
                onClick={() => navigate("/aiphotosortingdemo")}
                // onClick={() => {
                //   setUrlSelected(videoUrl?.faceDetection)
                //   setPopup(true)
                // }}
                className="px-[24px] py-[10px] rounded-[50px] border-[1px] border-[#CACACA] bg-[#FFF] hover:opacity-[0.8] cursor-pointer"
              >
                <p className="font_medium_16_14 text-[#000]">Show Demo</p>
              </button>
              <a
                href="https://crm.memshots.com"
                target="_blank"
                rel="noreferrer"
              >
                <button className="px-[24px] py-[10px] rounded-[50px] bg-[#8B34FD] hover:opacity-[0.8] cursor-pointer">
                  <p className="font_medium_16_14 text-[#FFFFFF]">Buy Now</p>
                </button>
              </a>
            </div>
          </div>
        </div>

        {/* section-2 */}

        <div
          ref={albumref}
          className="w-full h-full mt-[80px] lg:mt-[160px] grid lg:grid-cols-2 lg:gap-[60px] gap-[30px] lg:px-[78px] "
        >
          <img
            src={signatureAlbum}
            alt=""
            className="w-full h-full rounded-[12px] object-cover lg:hidden"
          />
          <div className="">
            <p className="font_medium_18 text-[#D23232] ">Signature Album</p>
            <p className="font_semibold_32 text-[#000000] mt-[8px]">
              Modern way to save your memorable photos
            </p>
            <p className="font_normal_16 text-[#000000] mt-[20px]">
              Each album is precisely crafted to preserve your most precious
              moments, blending high-quality images with elegant design.
            </p>
            <div className="flex flex-col gap-[24px] lg:gap-[40px] mt-[30px] lg:mt-[38px]">
              <div className="flex gap-[12px] items-center">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 5.6L19 7L17.6 4.5L19 2L16.5 3.4L14 2L15.4 4.5L14 7L16.5 5.6ZM4.5 15.4L2 14L3.4 16.5L2 19L4.5 17.6L7 19L5.6 16.5L7 14L4.5 15.4ZM2 2L3.4 4.5L2 7L4.5 5.6L7 7L5.6 4.5L7 2L4.5 3.4L2 2ZM10.66 12.78L8.22 10.34L10.34 8.22L12.78 10.66L10.66 12.78ZM9.63 7.29L7.29 9.63C6.9 10 6.9 10.65 7.29 11.04L18.96 22.71C19.35 23.1 20 23.1 20.37 22.71L22.71 20.37C23.1 20 23.1 19.35 22.71 18.96L11.04 7.29C10.65 6.9 10 6.9 9.63 7.29Z"
                      fill="#D23232"
                    />
                  </svg>
                </div>
                <p className="font_medium_13 text-[#161616]">
                  Enjoy your digital photo album on any device, anytime, with
                  our mobile-friendly platform.
                </p>
              </div>
              <div className="flex gap-[12px] items-center">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 5.6L19 7L17.6 4.5L19 2L16.5 3.4L14 2L15.4 4.5L14 7L16.5 5.6ZM4.5 15.4L2 14L3.4 16.5L2 19L4.5 17.6L7 19L5.6 16.5L7 14L4.5 15.4ZM2 2L3.4 4.5L2 7L4.5 5.6L7 7L5.6 4.5L7 2L4.5 3.4L2 2ZM10.66 12.78L8.22 10.34L10.34 8.22L12.78 10.66L10.66 12.78ZM9.63 7.29L7.29 9.63C6.9 10 6.9 10.65 7.29 11.04L18.96 22.71C19.35 23.1 20 23.1 20.37 22.71L22.71 20.37C23.1 20 23.1 19.35 22.71 18.96L11.04 7.29C10.65 6.9 10 6.9 9.63 7.29Z"
                      fill="#D23232"
                    />
                  </svg>
                </div>
                <p className="font_medium_13 text-[#161616]">
                  Securely store and easily share your digital photos with
                  friends and family anytime.
                </p>
              </div>
              <div className="flex gap-[12px] items-center">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 5.6L19 7L17.6 4.5L19 2L16.5 3.4L14 2L15.4 4.5L14 7L16.5 5.6ZM4.5 15.4L2 14L3.4 16.5L2 19L4.5 17.6L7 19L5.6 16.5L7 14L4.5 15.4ZM2 2L3.4 4.5L2 7L4.5 5.6L7 7L5.6 4.5L7 2L4.5 3.4L2 2ZM10.66 12.78L8.22 10.34L10.34 8.22L12.78 10.66L10.66 12.78ZM9.63 7.29L7.29 9.63C6.9 10 6.9 10.65 7.29 11.04L18.96 22.71C19.35 23.1 20 23.1 20.37 22.71L22.71 20.37C23.1 20 23.1 19.35 22.71 18.96L11.04 7.29C10.65 6.9 10 6.9 9.63 7.29Z"
                      fill="#D23232"
                    />
                  </svg>
                </div>
                <p className="font_medium_13 text-[#161616]">
                  Organize your photos effortlessly with our advanced sorting
                  features.
                </p>
              </div>
            </div>
            <div className="flex gap-[24px] mt-[30px] lg:mt-[60px]">
              <Link to={"https://app.memshots.com/amayaandayan?from=shootmatesdemo"}>
                <button
                  // onClick={() => {
                  //   setUrlSelected(videoUrl?.album);
                  //   setPopup(true);
                  // }}
                  className="px-[24px] py-[10px] rounded-[50px] border-[1px] border-[#CACACA] bg-[#FFF] hover:opacity-[0.8] cursor-pointer"
                >
                  <p className="font_medium_16_14 text-[#000]">View Sample</p>
                </button>
              </Link>
              <a
                href="https://crm.memshots.com"
                target="_blank"
                rel="noreferrer"
              >
                <button className="px-[24px] py-[10px] rounded-[50px] bg-[#D23232] hover:opacity-[0.8] cursor-pointer">
                  <p className="font_medium_16_14 text-[#FFFFFF]">Buy Now</p>
                </button>
              </a>
            </div>
          </div>
          <img
            src={signatureAlbum}
            alt=""
            className="w-full h-full rounded-[12px] object-cover hidden lg:grid"
          />
        </div>

        {/* section-3 */}

        {/* <div
          ref={invitationref}
          className="w-full h-full mt-[80px] lg:mt-[160px] grid lg:grid-cols-2 lg:gap-[60px] gap-[30px] lg:px-[78px] "
        >
          <img
            src={onlineInvitation}
            alt=""
            className="w-full h-full rounded-[12px] object-cover"
          />
          <div className="">
            <p className="font_medium_18 text-[#F73864] ">Online Invitation</p>
            <p className="font_semibold_32 text-[#000000] mt-[8px]">
              Newly way to invite peoples to your parties.
            </p>
            <p className="font_normal_16 text-[#000000] mt-[20px]">
              Deliver your guests’ personal photos with the best in world-class
              Face Recognition technology.
            </p>
            <div className="flex flex-col gap-[24px] lg:gap-[40px] mt-[30px] lg:mt-[38px]">
              <div className="flex gap-[12px] items-center">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 5.6L19 7L17.6 4.5L19 2L16.5 3.4L14 2L15.4 4.5L14 7L16.5 5.6ZM4.5 15.4L2 14L3.4 16.5L2 19L4.5 17.6L7 19L5.6 16.5L7 14L4.5 15.4ZM2 2L3.4 4.5L2 7L4.5 5.6L7 7L5.6 4.5L7 2L4.5 3.4L2 2ZM10.66 12.78L8.22 10.34L10.34 8.22L12.78 10.66L10.66 12.78ZM9.63 7.29L7.29 9.63C6.9 10 6.9 10.65 7.29 11.04L18.96 22.71C19.35 23.1 20 23.1 20.37 22.71L22.71 20.37C23.1 20 23.1 19.35 22.71 18.96L11.04 7.29C10.65 6.9 10 6.9 9.63 7.29Z"
                      fill="#F73864"
                    />
                  </svg>
                </div>
                <p className="font_medium_13 text-[#161616]">
                  Guests can ‘pre-register’ their faces via a QR code to and
                  automatically receive their pictures from the event via
                  Whatsapp link.
                </p>
              </div>
              <div className="flex gap-[12px] items-center">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 5.6L19 7L17.6 4.5L19 2L16.5 3.4L14 2L15.4 4.5L14 7L16.5 5.6ZM4.5 15.4L2 14L3.4 16.5L2 19L4.5 17.6L7 19L5.6 16.5L7 14L4.5 15.4ZM2 2L3.4 4.5L2 7L4.5 5.6L7 7L5.6 4.5L7 2L4.5 3.4L2 2ZM10.66 12.78L8.22 10.34L10.34 8.22L12.78 10.66L10.66 12.78ZM9.63 7.29L7.29 9.63C6.9 10 6.9 10.65 7.29 11.04L18.96 22.71C19.35 23.1 20 23.1 20.37 22.71L22.71 20.37C23.1 20 23.1 19.35 22.71 18.96L11.04 7.29C10.65 6.9 10 6.9 9.63 7.29Z"
                      fill="#F73864"
                    />
                  </svg>
                </div>
                <p className="font_medium_13 text-[#161616]">
                  Lets your guests find and download their high-resolution
                  pictures by simply clicking a selfie.
                </p>
              </div>
              <div className="flex gap-[12px] items-center">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 5.6L19 7L17.6 4.5L19 2L16.5 3.4L14 2L15.4 4.5L14 7L16.5 5.6ZM4.5 15.4L2 14L3.4 16.5L2 19L4.5 17.6L7 19L5.6 16.5L7 14L4.5 15.4ZM2 2L3.4 4.5L2 7L4.5 5.6L7 7L5.6 4.5L7 2L4.5 3.4L2 2ZM10.66 12.78L8.22 10.34L10.34 8.22L12.78 10.66L10.66 12.78ZM9.63 7.29L7.29 9.63C6.9 10 6.9 10.65 7.29 11.04L18.96 22.71C19.35 23.1 20 23.1 20.37 22.71L22.71 20.37C23.1 20 23.1 19.35 22.71 18.96L11.04 7.29C10.65 6.9 10 6.9 9.63 7.29Z"
                      fill="#F73864"
                    />
                  </svg>
                </div>
                <p className="font_medium_13 text-[#161616]">
                  Smartly identify and group photos of people to help you
                  visually find photos quickly.
                </p>
              </div>
            </div>
            <div className="flex gap-[24px] mt-[30px] lg:mt-[60px]">
              <button
                onClick={() => {
                  setUrlSelected(videoUrl?.invitation);
                  setPopup(true);
                }}
                className="px-[24px] py-[10px] rounded-[50px] border-[1px] border-[#CACACA] bg-[#FFF] hover:opacity-[0.8] cursor-pointer"
              >
                <p className="font_medium_16_14 text-[#000]">Show video</p>
              </button>
              <a href="https://crm.shootmates.com" target="_blank">
                <button className="px-[24px] py-[10px] rounded-[50px] bg-[#F73864] hover:opacity-[0.8] cursor-pointer">
                  <p className="font_medium_16_14 text-[#FFFFFF]">Buy Now</p>
                </button>
              </a>
            </div>
          </div>
        </div> */}

        {/* section-4 */}

        <div
          ref={desktoptoolref}
          className="w-full h-full mt-[80px] lg:mt-[160px] grid lg:grid-cols-2 lg:gap-[60px] gap-[30px] lg:px-[78px] "
        >
          <img
            src={desktopTool}
            alt=""
            className="w-full h-full rounded-[12px] object-cover lg:hidden"
          />
          <div className="">
            <p className="font_medium_18 text-[#4D58E3] ">Desktop Tool</p>
            <p className="font_semibold_32 text-[#000000] mt-[8px]">
              Modern way to upload photos instantly to work, or share.
            </p>
            <p className="font_normal_16 text-[#000000] mt-[20px]">
              Deliver your guests’ personal photos with the best in world-class
              Face Recognition technology.
            </p>
            <div className="flex flex-col gap-[24px] lg:gap-[40px] mt-[30px] lg:mt-[38px]">
              <div className="flex gap-[12px] items-center">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 5.6L19 7L17.6 4.5L19 2L16.5 3.4L14 2L15.4 4.5L14 7L16.5 5.6ZM4.5 15.4L2 14L3.4 16.5L2 19L4.5 17.6L7 19L5.6 16.5L7 14L4.5 15.4ZM2 2L3.4 4.5L2 7L4.5 5.6L7 7L5.6 4.5L7 2L4.5 3.4L2 2ZM10.66 12.78L8.22 10.34L10.34 8.22L12.78 10.66L10.66 12.78ZM9.63 7.29L7.29 9.63C6.9 10 6.9 10.65 7.29 11.04L18.96 22.71C19.35 23.1 20 23.1 20.37 22.71L22.71 20.37C23.1 20 23.1 19.35 22.71 18.96L11.04 7.29C10.65 6.9 10 6.9 9.63 7.29Z"
                      fill="#4D58E3"
                    />
                  </svg>
                </div>
                <p className="font_medium_13 text-[#161616]">
                  Studios can efficiently upload a bulk number of photos.
                </p>
              </div>
              <div className="flex gap-[12px] items-center">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 5.6L19 7L17.6 4.5L19 2L16.5 3.4L14 2L15.4 4.5L14 7L16.5 5.6ZM4.5 15.4L2 14L3.4 16.5L2 19L4.5 17.6L7 19L5.6 16.5L7 14L4.5 15.4ZM2 2L3.4 4.5L2 7L4.5 5.6L7 7L5.6 4.5L7 2L4.5 3.4L2 2ZM10.66 12.78L8.22 10.34L10.34 8.22L12.78 10.66L10.66 12.78ZM9.63 7.29L7.29 9.63C6.9 10 6.9 10.65 7.29 11.04L18.96 22.71C19.35 23.1 20 23.1 20.37 22.71L22.71 20.37C23.1 20 23.1 19.35 22.71 18.96L11.04 7.29C10.65 6.9 10 6.9 9.63 7.29Z"
                      fill="#4D58E3"
                    />
                  </svg>
                </div>
                <p className="font_medium_13 text-[#161616]">
                  The process allows for swift uploading, saving time and
                  enhancing productivity.
                </p>
              </div>
              <div className="flex gap-[12px] items-center">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 5.6L19 7L17.6 4.5L19 2L16.5 3.4L14 2L15.4 4.5L14 7L16.5 5.6ZM4.5 15.4L2 14L3.4 16.5L2 19L4.5 17.6L7 19L5.6 16.5L7 14L4.5 15.4ZM2 2L3.4 4.5L2 7L4.5 5.6L7 7L5.6 4.5L7 2L4.5 3.4L2 2ZM10.66 12.78L8.22 10.34L10.34 8.22L12.78 10.66L10.66 12.78ZM9.63 7.29L7.29 9.63C6.9 10 6.9 10.65 7.29 11.04L18.96 22.71C19.35 23.1 20 23.1 20.37 22.71L22.71 20.37C23.1 20 23.1 19.35 22.71 18.96L11.04 7.29C10.65 6.9 10 6.9 9.63 7.29Z"
                      fill="#4D58E3"
                    />
                  </svg>
                </div>
                <p className="font_medium_13 text-[#161616]">
                  Enjoy seamless integration for quick and easy photo
                  management.
                </p>
              </div>
            </div>
            <div className="flex gap-[24px] mt-[30px] lg:mt-[60px]">
              <button
                onClick={() => {
                  setUrlSelected(videoUrl?.desktopTool);
                  setPopup(true);
                }}
                className="px-[24px] py-[10px] rounded-[50px] border-[1px] border-[#CACACA] bg-[#FFF] hover:opacity-[0.8] cursor-pointer"
              >
                <p className="font_medium_16_14 text-[#000]">Show video</p>
              </button>
              <a
                href="https://crm.memshots.com"
                target="_blank"
                rel="noreferrer"
              >
                <button className="px-[24px] py-[10px] rounded-[50px] bg-[#4D58E3] hover:opacity-[0.8] cursor-pointer">
                  <p className="font_medium_16_14 text-[#FFFFFF]">Buy Now</p>
                </button>
              </a>
            </div>
          </div>
          <img
            src={desktopTool}
            alt=""
            className="w-full h-full rounded-[12px] object-cover hidden lg:grid"
          />
        </div>

        {/* section-5 */}

        <div
          ref={selectiontoolref}
          className="w-full h-full mt-[80px] lg:mt-[160px] grid lg:grid-cols-2 lg:gap-[60px] gap-[30px] lg:px-[78px] "
        >
          <img
            src={selectionTool}
            alt=""
            className="w-full h-full rounded-[12px] object-cover"
          />
          <div className="">
            <p className="font_medium_18 text-[#0FA958] ">Photo Selection</p>
            <p className="font_semibold_32 text-[#000000] mt-[8px]">
              One and only easy way to select photos from clients.
            </p>
            <p className="font_normal_16 text-[#000000] mt-[20px]">
              Solving the problem of passing pen drives and back to back
              corrections in photo selection.
            </p>
            <div className="flex flex-col gap-[24px] lg:gap-[40px] mt-[30px] lg:mt-[38px]">
              <div className="flex gap-[12px] items-center">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 5.6L19 7L17.6 4.5L19 2L16.5 3.4L14 2L15.4 4.5L14 7L16.5 5.6ZM4.5 15.4L2 14L3.4 16.5L2 19L4.5 17.6L7 19L5.6 16.5L7 14L4.5 15.4ZM2 2L3.4 4.5L2 7L4.5 5.6L7 7L5.6 4.5L7 2L4.5 3.4L2 2ZM10.66 12.78L8.22 10.34L10.34 8.22L12.78 10.66L10.66 12.78ZM9.63 7.29L7.29 9.63C6.9 10 6.9 10.65 7.29 11.04L18.96 22.71C19.35 23.1 20 23.1 20.37 22.71L22.71 20.37C23.1 20 23.1 19.35 22.71 18.96L11.04 7.29C10.65 6.9 10 6.9 9.63 7.29Z"
                      fill="#0FA958"
                    />
                  </svg>
                </div>
                <p className="font_medium_13 text-[#161616]">
                  Users have the liberty to select their photos at any time,
                  from any location, on any device.
                </p>
              </div>
              <div className="flex gap-[12px] items-center">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 5.6L19 7L17.6 4.5L19 2L16.5 3.4L14 2L15.4 4.5L14 7L16.5 5.6ZM4.5 15.4L2 14L3.4 16.5L2 19L4.5 17.6L7 19L5.6 16.5L7 14L4.5 15.4ZM2 2L3.4 4.5L2 7L4.5 5.6L7 7L5.6 4.5L7 2L4.5 3.4L2 2ZM10.66 12.78L8.22 10.34L10.34 8.22L12.78 10.66L10.66 12.78ZM9.63 7.29L7.29 9.63C6.9 10 6.9 10.65 7.29 11.04L18.96 22.71C19.35 23.1 20 23.1 20.37 22.71L22.71 20.37C23.1 20 23.1 19.35 22.71 18.96L11.04 7.29C10.65 6.9 10 6.9 9.63 7.29Z"
                      fill="#0FA958"
                    />
                  </svg>
                </div>
                <p className="font_medium_13 text-[#161616]">
                  User-friendly design ensures a seamless experience, making
                  photo selection a breeze for everyone.
                </p>
              </div>
              <div className="flex gap-[12px] items-center">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 5.6L19 7L17.6 4.5L19 2L16.5 3.4L14 2L15.4 4.5L14 7L16.5 5.6ZM4.5 15.4L2 14L3.4 16.5L2 19L4.5 17.6L7 19L5.6 16.5L7 14L4.5 15.4ZM2 2L3.4 4.5L2 7L4.5 5.6L7 7L5.6 4.5L7 2L4.5 3.4L2 2ZM10.66 12.78L8.22 10.34L10.34 8.22L12.78 10.66L10.66 12.78ZM9.63 7.29L7.29 9.63C6.9 10 6.9 10.65 7.29 11.04L18.96 22.71C19.35 23.1 20 23.1 20.37 22.71L22.71 20.37C23.1 20 23.1 19.35 22.71 18.96L11.04 7.29C10.65 6.9 10 6.9 9.63 7.29Z"
                      fill="#0FA958"
                    />
                  </svg>
                </div>
                <p className="font_medium_13 text-[#161616]">
                  Easily share your selected photos with a secure PIN and keep
                  your personal photos safe.
                </p>
              </div>
            </div>
            <div className="flex gap-[24px] mt-[30px] lg:mt-[60px]">
              <Link to={"https://selection.memshots.com/HarshanRithika/select?from=shootmatesdemo"} >
                <button
                  // onClick={() => {
                  //   setUrlSelected(videoUrl?.selectionTool);
                  //   setPopup(true);
                  // }}
                  className="px-[24px] py-[10px] rounded-[50px] border-[1px] border-[#CACACA] bg-[#FFF] hover:opacity-[0.8] cursor-pointer"
                >
                  <p className="font_medium_16_14 text-[#000]">Try Demo</p>
                </button>
              </Link>
              <a
                href="https://crm.memshots.com"
                target="_blank"
                rel="noreferrer"
              >
                <button className="px-[24px] py-[10px] rounded-[50px] bg-[#0FA958] hover:opacity-[0.8] cursor-pointer">
                  <p className="font_medium_16_14 text-[#FFFFFF]">Buy Now</p>
                </button>
              </a>
            </div>
          </div>
        </div>

        {/* section-6 */}

        <div
          ref={crmref}
          className="w-full h-full mt-[80px] lg:mt-[160px] grid lg:grid-cols-2 lg:gap-[60px] gap-[30px] lg:px-[78px] "
        >
          <img
            src={shootmatesCRM}
            alt=""
            className="w-full h-full rounded-[12px] object-cover lg:hidden"
          />
          <div className="">
            <p className="font_medium_18 text-[#FE5E1A] ">Memshots CRM</p>
            <p className="font_semibold_32 text-[#000000] mt-[8px]">
              Run your studio business with its CRM and maintain work flow easy.
            </p>
            <p className="font_normal_16 text-[#000000] mt-[20px]">
              Advanced way to maintain your works.
            </p>
            <div className="flex flex-col gap-[24px] lg:gap-[40px] mt-[30px] lg:mt-[38px]">
              <div className="flex gap-[12px] items-center">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 5.6L19 7L17.6 4.5L19 2L16.5 3.4L14 2L15.4 4.5L14 7L16.5 5.6ZM4.5 15.4L2 14L3.4 16.5L2 19L4.5 17.6L7 19L5.6 16.5L7 14L4.5 15.4ZM2 2L3.4 4.5L2 7L4.5 5.6L7 7L5.6 4.5L7 2L4.5 3.4L2 2ZM10.66 12.78L8.22 10.34L10.34 8.22L12.78 10.66L10.66 12.78ZM9.63 7.29L7.29 9.63C6.9 10 6.9 10.65 7.29 11.04L18.96 22.71C19.35 23.1 20 23.1 20.37 22.71L22.71 20.37C23.1 20 23.1 19.35 22.71 18.96L11.04 7.29C10.65 6.9 10 6.9 9.63 7.29Z"
                      fill="#FE5E1A"
                    />
                  </svg>
                </div>
                <p className="font_medium_13 text-[#161616]">
                  Elevate your event experiences with Memshots CRM, which
                  seamlessly integrates and efficiently manages photos
                </p>
              </div>
              <div className="flex gap-[12px] items-center">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 5.6L19 7L17.6 4.5L19 2L16.5 3.4L14 2L15.4 4.5L14 7L16.5 5.6ZM4.5 15.4L2 14L3.4 16.5L2 19L4.5 17.6L7 19L5.6 16.5L7 14L4.5 15.4ZM2 2L3.4 4.5L2 7L4.5 5.6L7 7L5.6 4.5L7 2L4.5 3.4L2 2ZM10.66 12.78L8.22 10.34L10.34 8.22L12.78 10.66L10.66 12.78ZM9.63 7.29L7.29 9.63C6.9 10 6.9 10.65 7.29 11.04L18.96 22.71C19.35 23.1 20 23.1 20.37 22.71L22.71 20.37C23.1 20 23.1 19.35 22.71 18.96L11.04 7.29C10.65 6.9 10 6.9 9.63 7.29Z"
                      fill="#FE5E1A"
                    />
                  </svg>
                </div>
                <p className="font_medium_13 text-[#161616]">
                  Easily create and manage events with user-friendly features,
                  including AI Face Scan.
                </p>
              </div>
              <div className="flex gap-[12px] items-center">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 5.6L19 7L17.6 4.5L19 2L16.5 3.4L14 2L15.4 4.5L14 7L16.5 5.6ZM4.5 15.4L2 14L3.4 16.5L2 19L4.5 17.6L7 19L5.6 16.5L7 14L4.5 15.4ZM2 2L3.4 4.5L2 7L4.5 5.6L7 7L5.6 4.5L7 2L4.5 3.4L2 2ZM10.66 12.78L8.22 10.34L10.34 8.22L12.78 10.66L10.66 12.78ZM9.63 7.29L7.29 9.63C6.9 10 6.9 10.65 7.29 11.04L18.96 22.71C19.35 23.1 20 23.1 20.37 22.71L22.71 20.37C23.1 20 23.1 19.35 22.71 18.96L11.04 7.29C10.65 6.9 10 6.9 9.63 7.29Z"
                      fill="#FE5E1A"
                    />
                  </svg>
                </div>
                <p className="font_medium_13 text-[#161616]">
                  Enjoy secure, private photo storage and seamless photo
                  uploads.
                </p>
              </div>
            </div>
            <div className="flex gap-[24px] mt-[30px] lg:mt-[60px]">
              <button
                onClick={() => {
                  setUrlSelected(videoUrl?.crm);
                  setPopup(true);
                }}
                className="px-[24px] py-[10px] rounded-[50px] border-[1px] border-[#CACACA] bg-[#FFF] hover:opacity-[0.8] cursor-pointer"
              >
                <p className="font_medium_16_14 text-[#000]">Show video</p>
              </button>
              <a
                href="https://crm.memshots.com"
                target="_blank"
                rel="noreferrer"
              >
                <button className="px-[24px] py-[10px] rounded-[50px] bg-[#FE5E1A] hover:opacity-[0.8] cursor-pointer">
                  <p className="font_medium_16_14 text-[#FFFFFF]">Buy Now</p>
                </button>
              </a>
            </div>
          </div>
          <img
            src={shootmatesCRM}
            alt=""
            className="w-full h-full rounded-[12px] object-cover hidden lg:grid"
          />
        </div>
      </div>

      <Clients />
      <div className=" w-full flex justify-center px-[5.26%] lg:px-[8.33%]">
        <div className="horizontalSeperator max-w-[1440px]"></div>
      </div>
      <Events />

      {/* botton banner */}

      <div className="bg-[#FF5349] w-full px-[5.26%] lg:px-[8.33%] mt-[40px] lg:mt-[60px] py-[40px] lg:py-[60px] flex flex-col items-center text-center mb-[60px]">
        <p className="max-w-[767px] text-[#FFF] font_semibold_40_24">
          Experience a{" "}
          <span className="font_semibold_40_24_ebGaramond">free trail</span> of
          Memshots tools and features{" "}
          <span className="font_semibold_40_24_ebGaramond">
            completely for free
          </span>
        </p>
        <p className="max-w-[404px] mt-[40px] lg:mt-[12px] text-[#FFF] font_medium_16_14">
          Our team is all set to show you how it’s works and go through on every
          side of our tools
        </p>
        <a
          href="https://calendly.com/shootmates/30min"
          target="_blank"
          rel="noreferrer"
        >
          <button className="mt-[24px] px-[24px] py-[10px] rounded-[50px] bg-[#FFF] text-[#000] font_medium_16_14 cursor-pointer hover:opacity-[0.9] hover:text-[#8B34FD]">
            Request a demo
          </button>
        </a>
      </div>

      <Footer />
      <NavbarBottom />
    </div>
  );
}

export default HomeNew;
