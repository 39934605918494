import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import styles from "./Popup.module.css";
import backMobile from "../../Assets/Images/studioBackMobile.svg";
import backWeb from "../../Assets/Images/studioBackWeb.svg";
import Right from "../../Assets/ryt.svg";
import Left from "../../Assets/lftw.svg";
import { useNavigate } from "react-router";
import "./Popup.css";
import * as _ from "lodash";

const Popup = ({
  clickedImg,
  setClickedImg,
  handelRotationRight,
  handelRotationLeft,
  contestDetails,
  isPrevDisabled,
  isNextDisabled,
}) => {
  const handleClick = (e) => {
    setClickedImg(null);
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  const backIcon = (innerWidth) => {
    if (innerWidth <= 768) {
      return backMobile;
    } else {
      return backWeb;
    }
  };
  return (
    <div className={` dismiss z-[100] mx-auto ${styles.popup}`}>
      <div
        className={`h-[100%] w-[100%] max-w-[1440px] mx-auto bg-[#000000] relative no-scrollbar overflow-y-scroll text-white relative`}
      >
        <div className="absolute top-[16px] left-[16px] z-10 cursor-pointer">
          <img src={backIcon(window.innerWidth)} alt="" onClick={handleClick} />
        </div>
        <div
          className={`h-full w-full flex items-center justify-center relative `}
          style={{
            background: `url(${
              clickedImg?.fileType?.startsWith("video")
                ? clickedImg?.thumbnailUrl
                : clickedImg?.url
            }) center center/cover no-repeat`,
          }}
        >
          <div className={styles.imageBg}>
            {clickedImg?.fileType?.startsWith("video") ? (
              <ReactPlayer
                config={{
                  file: { attributes: { controlsList: "nodownload" } },
                }}
                playing={false}
                controls={true}
                width="100%"
                height="100%"
                url={clickedImg?.url}
              />
            ) : (
              <img
                src={clickedImg?.url}
                alt=""
                className="h-[100%] w-[100%] mx-auto object-contain"
              />
            )}
            <>
              <button
                className="w-[32px] h-[32px] disabled:opacity-0  absolute top-[45%] left-[9px]  backdrop-blur-[27px] rounded-full z-10"
                disabled={isPrevDisabled}
                onClick={handelRotationLeft}
              >
                <img className="w-[32px] h-[32px]  " src={Left} />
              </button>
              <button
                className="w-[32px] h-[32px] disabled:opacity-0  absolute  top-[45%] right-[9px] backdrop-blur-[27px] rounded-full z-10"
                onClick={handelRotationRight}
                disabled={isNextDisabled}
              >
                <img src={Right} className="w-[32px] h-[32px] " alt="" />
              </button>
            </>
          </div>
        </div>

        <div className="px-[20px] mt-[40px] ">
          <div className="seperator " />
        </div>
      </div>
      {/* {share && (
        <SharePopup showShare={ShareHandle} contestant={contestContent} />
      )} */}
    </div>
  );
};

export default Popup;
