import React from 'react';

const groupBy = (array, key) => {
    return array.reduce((result, item) => {
        const keyValue = item[key];
        (result[keyValue] = result[keyValue] || []).push(item);
        return result;
    }, {});
};

const PricingList = ({ pricingData, titleKey, totalPrice, handleDeleteItem }) => {
    const groupedData = groupBy(pricingData, 'option');

    return (
        <div>
            <div className="fixedleft-0 right-0 bg-[#0766AD] p-4 mb-[10px]">
                <div className="flex justify-between items-center">
                    <div className="text-white font-semibold">Total Price: {totalPrice}</div>
                    <button className="px-4 py-2 bg-[#0AA1DD] text-white rounded-md">Buy Now</button>
                </div>
            </div>
            {Object.entries(groupedData).map(([option, items]) => (
                <div key={option} className="mb-4">
                    <h3 className="text-xl font-semibold mb-4 border-b-2 border-gray-300 pb-2">{titleKey(option)}</h3>
                    {items.map((item) => (
                        <div key={item.id} className="bg-white p-6 rounded-lg shadow-md mb-4">
                            <div className="mb-2">
                                <label className="block mb-2 font-semibold">{titleKey(option)} {item?.id}</label>
                            </div>
                            <div className="mb-2">
                                <label className="block mb-2">Total Price</label>
                                <div>{item?.totalPrice}</div>
                            </div>
                            <button
                                className="px-4 py-2 bg-red-500 text-white rounded-md"
                                onClick={() => handleDeleteItem(item.id)}
                            >
                                Delete
                            </button>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default PricingList;
