import React from "react";
import { Link } from "react-router-dom";

function PricingComponent({ data }) {
  return (
    <div className="w-full grid grid-rows-1 lg:grid-cols-4 gap-[20px] mt-[60px] xl:mt-[120px] ">
      <div className="flex flex-col items-center text-center lg:items-start lg:text-left">
        <p className="font_semibold_24 text-[#000]">{data?.heading}</p>
      </div>
      {data?.datas?.map((item, i) => (
        <div className="px-[16px] py-[20px] border-[2px] flex flex-col justify-between border-[#8DA3DC] rounded-[12px] cursor-pointer hover:opacity-[0.8]">
          <p className="font_semibold_16 text-[#000]">{item?.heading}</p>
          <div className="mt-4 ml-[20px]">
            {item?.points?.map((item) => (
              <ul style={{ listStyleType: "disc" }}>
                <li>{item}</li>
              </ul>
            ))}
          </div>
          <p className="font_normal_16 text-[#565656] mt-[28px]">
            {item?.text}
          </p>
          <div>
            <p className="font_semibold_24 text-[#000] mt-[40px]">
              {typeof item?.price === "number"
                ? `${item?.price}.00 INR`
                : item?.price}
            </p>
            <Link to={"https://crm.shootmates.com"}>
              <button
                style={{
                  background:
                    i === 0 ? "#D9F3AF" : i === 1 ? "#C1AFF3" : "#F3AFBF",
                }}
                className="px-[24px] w-[50%] py-[10px] rounded-[50px] bg-[#D9F3AF] mt-[24px]"
              >
                <p className="font_medium_16 text-[#000]">Pay Now</p>
              </button>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PricingComponent;
