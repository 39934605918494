import React from 'react';
import Pricing from "../../Assets/pricing.json";

const PricingPopup = ({
    setPopupOpen,
    addPricedItem,
    selectedOption,
    handleSelectOption,
    handleAddItem,
    titleKey
}) => {

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-8 rounded-lg shadow-md w-96">
                <h2 className="text-xl font-semibold mb-4">Add an Item To Estimate</h2>
                <div className="mb-4">
                    {Object.keys(Pricing).map((option) => (
                        <div
                            key={option}
                            className={`mb-2 p-4 cursor-pointer hover:bg-gray-100 transition duration-300 shadow-md ${
                                selectedOption === option ? 'bg-blue-100' : 'bg-white'
                            }`}
                            onClick={() => handleSelectOption(option)}
                        >
                            <p className={`text-lg font-semibold ${selectedOption === option ? 'text-blue-500' : 'text-black'}`}>
                                {titleKey(option)}
                            </p>
                        </div>
                    ))}
                </div>
                <button
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    onClick={handleAddItem}
                    disabled={!selectedOption}
                >
                    Add Item
                </button>
                <button
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 ml-2"
                    onClick={() => setPopupOpen(false)}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default PricingPopup;
