import React, { useEffect, useState } from "react";
import Navbar from "../Components/NavbarNew";
import faceImage from "../Assets/Images/Demo/demoFace.png";
import styles from "./Css/facedetection.module.css";
import GuidlinesDemo from "../Components/GuidlinesDemo";
import { useNavigate, useParams } from "react-router-dom";
import { faceStorage } from "../store/memshots-firebase";
import { ref, uploadBytes } from "firebase/storage";
import axios from "axios";
import MessagePopupNew from "../Components/Popups/MessagePopupNew";
import guidelinesUserImage from "../Assets/Images/Demo/guidelinesUserImage.jpg";

function FaceDetectionDemoTakeSelfie() {
    let { folderId } = useParams();
    const navigate = useNavigate();
    const [whatsappNumber, setWhatsappNumber] = useState("");
    const [email, setEmail] = useState("");
    const [currentState, setCurrentState] = useState("Uploading Selfie..");
    const [whatsappError, setWhatsappError] = useState("");
    const [fileError, setFileError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [uploadedImage, setUploadedImage] = useState(null);
    const [fileToUpload, setFileToUpload] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const webcamRef = React.useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const memshotAlbumId = parseInt(process.env.REACT_APP_MEMSHOTSALBUMID);

    const togglePopup = () => {
        navigate(`/aiphotosortingdemo/${folderId}/upload`);
    };

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const handleCapture = (event) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        const imageUrl = URL.createObjectURL(file);
        setFileToUpload(file);
        setUploadedImage(imageUrl);
        setFileError("");
    };

    const handleChoose = async () => {
        webcamRef.current.click();
    };

    const validateWhatsappNumber = (number) => {
        const regex = /^\+91[0-9]{10}$/;
        return regex.test(number);
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleWhatsappChange = (event) => {
        const number = event.target.value;
        setWhatsappNumber(number);
        if (!validateWhatsappNumber(number)) {
            setWhatsappError(
                "Please enter a valid Indian WhatsApp number starting with +91."
            );
        } else {
            setWhatsappError("");
        }
    };

    const handleEmailChange = (event) => {
        const email = event.target.value;
        setEmail(email);
        if (!validateEmail(email)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");
        }
    };

    const startSelfieUpload = async (file, memshotAlbumId) => {
        try {
            setIsUploading(true);
            const fileName = `${Date.now()}_${file?.name}`;
            const storageRef = ref(faceStorage, `/${memshotAlbumId}/${fileName}`);
            await uploadBytes(storageRef, file);
            const bucketName = "memshots_face_requests";
            const filePath = memshotAlbumId + "/" + fileName;
            const gcsBucketUrl = `https://storage.googleapis.com/${bucketName}/${filePath}`;
            setIsUploading(false);
            return { gcsBucketUrl: gcsBucketUrl, fileName: fileName };
        } catch (error) {
            console.error("Error uploading files to Firebase:", error);
            setIsUploading(false);
            alert("Error uploading files. Please try again.");
        }
    };

    const createFaceData = async (faceFile, type, memshotAlbumId) => {
        try {
            setIsRegistering(true);
            let dataTosend = {
                faceUrl: [faceFile],
                email: email,
                regWhatsAppNo: whatsappNumber,
                albumName: "EGL FD demo",
                albumUniqueText: "eglfacedetection",
            };
            const config = {
                method: "POST",
                url: `https://asia-south1-memshots-380011.cloudfunctions.net/memshotFaceDetection/api/memshot/facedetection/createfacedata/${memshotAlbumId}?requestType=${type}`,
                data: dataTosend,
            };
            const response = await axios(config);
            if (response.status === 201) {
                setIsRegistering(false);
                setIsUploading(false);
            }
        } catch (error) {
            console.log(error);
            setIsRegistering(false);
            setIsUploading(false);
        }
    };

    const handleSubmit = async () => {
        try {
            if (!fileToUpload) {
                setFileError("Please upload a selfie..");
                return;
            }
            if (!whatsappNumber && !email) {
                setFileError("Please enter either WhatsApp number or Email ID");
                return;
            }
            setCurrentState("Uploading Selfie..");
            const data = await startSelfieUpload(fileToUpload, memshotAlbumId);
            setCurrentState("Registering Selfie..");
            await createFaceData(data?.fileName, "NOCONSENT_REQUEST", memshotAlbumId);
            setShowPopup(true);
        } catch (error) {
            console.log("error", error);
        }
    };
    return (
        <div className="containFull bg-[#fff] ">
            <Navbar />
            {showPopup && <MessagePopupNew onClose={togglePopup} />}
            <div className="flex flex-col items-center px-[20px] lg:px-[80px]">
                <div className="w-full max-w-[1280px]">
                    <div className="w-full py-[24px] lg:py-[18px] flex justify-between ">
                        <div className={styles.attendeeButton}>Attendee</div>
                        <p className="font_semibold_24 text-[#000] w-full text-center">
                            Experience the AI Photo Delivery Demo
                        </p>
                        <div className="w-[110px] hidden lg:block"></div>
                    </div>
                    <div className="mt-[28px] lg:mt-[20px] flex justify-center">
                        <div className={styles.registerBoxShadow}>
                            <div className=" absolute left-[-30px] w-[120px]  transform -rotate-45  bg-[#497FFF]  py-[3px]">
                                <p className="font_medium_13 text-[#F6F6F6] text-center">
                                    Attendee
                                </p>
                            </div>
                            <p className="font_semibold_24_21">Upload your face</p>
                            {uploadedImage ? (
                                <>
                                    <img
                                        src={uploadedImage}
                                        alt=""
                                        className="w-[141px] h-[141px] rounded-full mt-[54px] object-cover"
                                    />
                                    <div
                                        onClick={handleChoose}
                                        className={styles.retakePhotoButton}
                                    >
                                        <input
                                            type="file"
                                            onChange={handleCapture}
                                            ref={webcamRef}
                                            className="hidden"
                                            accept="image/*"
                                            capture
                                        />
                                        <p>Retake Photo</p>
                                    </div>
                                    <div className="w-full px-[15px] my-[30px] flex flex-col  items-center">
                                        <p className="font_semibold_18 text-center">
                                            Where you want to get your photos
                                        </p>
                                        <input
                                            type="text"
                                            style={{
                                                marginTop: "30px",
                                            }}
                                            className={styles.inputField}
                                            value={whatsappNumber}
                                            onChange={handleWhatsappChange}
                                            placeholder="Enter your Whatsapp Number"
                                        />
                                        {whatsappError && (
                                            <p className="text-red-500 text-sm mt-1 text-center">
                                                {whatsappError}
                                            </p>
                                        )}
                                        <input
                                            type="text"
                                            style={{
                                                marginTop: "16px",
                                            }}
                                            className={styles.inputField}
                                            value={email}
                                            onChange={handleEmailChange}
                                            placeholder="Enter your Email ID"
                                        />
                                        {emailError && (
                                            <p className="text-red-500 text-sm mt-1 text-center">{emailError}</p>
                                        )}
                                    </div>
                                    <div className="horizontalSeperator mb-[16px]"></div>
                                    <div className="px-[15px] flex flex-col items-center">
                                        <button
                                            onClick={handleSubmit}
                                            className={`${styles.takeSelfieButton} `}
                                        >
                                            {isUploading || isRegistering ? currentState : "Register"}
                                        </button>
                                        {fileError && (
                                            <p className="text-red-500 text-sm mt-1 text-center">{fileError}</p>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <img
                                        src={faceImage}
                                        alt=""
                                        className="object-cover rounded-[12px] mt-[40px] max-w-[310px] lg:max-w-[320px]"
                                    />
                                    <button
                                        onClick={handleChoose}
                                        className={`${styles.takeSelfieButton} mt-[24px]`}
                                    >
                                        <input
                                            type="file"
                                            onChange={handleCapture}
                                            ref={webcamRef}
                                            className="hidden"
                                            accept="image/*"
                                            capture
                                        />
                                        <p>Take a selfie</p>
                                    </button>
                                    <div className="horizontalSeperator my-[16px] lg:my-[14px]"></div>
                                    <p className="px-[13px] lg:px-[28px] font_normal_12 text-center">
                                        Ensure your image depicts a face with both eyes open and
                                        clearly visible, avoiding any obstructions or tight crops.
                                        Opt for a photo displaying the full head and shoulders for
                                        optimal results.
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                    <GuidlinesDemo image={guidelinesUserImage} />
                </div>
            </div>
        </div>
    );
}

export default FaceDetectionDemoTakeSelfie;
