import React from 'react'
import aiAlbumIcon from '../../Assets/Images/aiAlbumIcon.svg'
import aiAlbumCover from '../../Assets/Images/aiAlbumCover.png'

function Album() {
    return (
        <div className='w-full h-full xl:px-[8.33%] mb-[80px] xl:mb-[120px]'>
            <div className='flex flex-col-reverse  xl:grid xl:grid-cols-6'>
                <div className='px-[5.26%] xl:px-0 xl:col-span-4 flex flex-col gap-[40px] xl:gap-[75px] justify-center'>
                    <div>
                        <p className='mb-[10px] xl:mb-[8px] font-medium text-[16px] xl:text-[18px] leading-[22px] tracking-[-0.32px] xl:tracking-[-0.36px] text-[#0139FF]'>Online Photo Album</p>
                        <p className='xl:max-w-[320px] mb-[16px] font-semibold text-[24px] xl:text-[32px] tracking-[-0.48px] xl:tracking-[-0.64px] text-[#161616]'>Modern Way to Deliver your Photos</p>
                        <p className='xl:max-w-[410px] text-[16px] leading-[22px] tracking-[-0.32px] text-[#161616]'>Use shootsmats to showcase albums digitally with exciting features. Get high attention leads, because of quality of presenting photos and videos</p>
                    </div>
                    <div className='flex flex-col gap-[40px]'>
                        <div className='flex gap-[12px] items-center'>
                            <img src={aiAlbumIcon} alt="" />
                            <p className='xl:max-w-[428px] font-medium text-[13px] leading-[18px] tracking-[-0.26px] text-[#161616]'>Responsive online photo album - Swipe phone and watch videos on any screens</p>
                        </div>
                        <div className='flex gap-[12px] items-center'>
                            <img src={aiAlbumIcon} alt="" />
                            <p className='xl:max-w-[428px] font-medium text-[13px] leading-[18px] tracking-[-0.26px] text-[#161616]'>Share it on anywhere you like, Instagram, Facebook or WhatsApp.</p>
                        </div>
                        <div className='flex gap-[12px] items-center'>
                            <img src={aiAlbumIcon} alt="" />
                            <p className='xl:max-w-[428px] font-medium text-[13px] leading-[18px] tracking-[-0.26px] text-[#161616]'>Bring your albums to life by using video reels. Also can make private albums for private clients</p>
                        </div>
                    </div>
                </div>
                <div className='xl:col-span-2 px-[13.85%] sm:px-[5.26%] xl:px-0 mb-[40px] xl:mb-0 flex justify-center sm:justify-start xl:justify-center'>
                    <img src={aiAlbumCover} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Album