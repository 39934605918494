import React, { useEffect } from "react";
import styles from "./MessagePopupNew.module.css";
import successIcon from '../../Assets/Images/Demo/successIcon.png'

function MessagePopupNew({ onClose }) {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "unset";
        };
    }, []);
    return (
        <div className="fixed top-[83px] left-0  h-[100%] w-[100%] min-h-[100vh] z-[100] bg-[#fff] flex justify-center items-center px-[20px] lg:px-0">
            <div className={styles.container}>
                <div className=" absolute left-[-30px] w-[120px]  transform -rotate-45  bg-[#497FFF]  py-[3px]">
                    <p className="font_medium_13 text-[#F6F6F6] text-center">Attendee</p>
                </div>
                <img src={successIcon} alt="" className="mt-[60px]" />
                <p className="font_medium_16 text-[#4A80FF]">Face Successfully Registered</p>
                <div className='horizontalSeperator my-[24px]'></div>
                <p className="font_semibold_24_21 text-center px-[24px]">Let’s try the user experience demo for photographers</p>
                <button
                    onClick={onClose}
                    className={styles.startDemoButton}
                >
                    Start Demo as a photographer
                </button>
            </div>
        </div>
    );
}

export default MessagePopupNew;
