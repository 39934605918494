import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import Footer from "../Components/Footer";

function Refund() {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="w-full h-full">
            <div className="min-h-[80vh]">
                <div className=" h-[79px] navbar flex items-center px-[20px]">
                    <p
                        onClick={() => {
                            navigate("/");
                        }}
                        className="underline cursor-pointer"
                    >
                        Back
                    </p>
                </div>
                <div className="p-[40px] w-full h-full flex flex-col text-[#000]">
                    <p className='mb-[20px]  font-bold text-[24px] lg:text-[40px]'>Cancellation & Refund Policy</p>
                    <p className="mb-[30px] text-[16px] lg:text-[21px]">No cancellations & Refunds are entertained.</p>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Refund;
