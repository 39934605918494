import React, { useEffect, useState } from "react";
// import { datas } from "../../constants/Constants";
import styles from "./styles.module.css";
import axios from "axios";

function Clients() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await axios.get(`/Json/Clients.json`);
      setData(response?.data?.clients);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className={`${styles.container}  px-[5.26%] lg:px-[8.33%]`}>
      <div className={styles.clientsDiv}>
        <div className="w-full">
          <p className="font_semibold_32_24 max-w-[600px]">
            Discover who's using <span className="dmSerifRegularItalic font_normal_32_24">Memshots</span> to capture and share memories.
          </p>
        </div>
        <div className={`${styles.clientsScrollSection} no-scrollbar`}>
          {data?.map((obj, i) => {
            return (
              <div key={i} className={styles.clientsCards}>
                <img src={obj?.image} alt="" />
                <p>{obj?.text}</p>
              </div>
            )
          }
          )}
        </div>
      </div>
    </div>
  );
}

export default Clients;
