import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import backMobile from "../Assets/Images/studioBackMobile.svg";
import backWeb from "../Assets/Images/studioBackWeb.svg";
import hamburger from "../Assets/Images/studioHam.svg";
import mail from "../Assets/Images/studioMail.svg";
import verified from "../Assets/Images/studioVerified.svg";
import symbol from "../Assets/Images/studioSymbol.svg";
import link from "../Assets/Images/studioLink.svg";
import StudioShowcase from "../Components/StudioPortfolio/StudioShowcase";
import StudioAbout from "../Components/StudioPortfolio/StudioAbout";
import StudioFAQ from "../Components/StudioPortfolio/StudioFAQ";
import StudioReviews from "../Components/StudioPortfolio/StudioReviews";
import styles from "../Components/styles/home.module.css";
import { isEmpty } from "lodash";
import Footer from "../Components/Footer";
import memthotsSmallicon from "../Assets/memthotsSmallicon.svg";
import StickyFooter from "../Components/StickyFooter/StickyFooter";
import EnquireForm from "../Components/EnquireForm/EnquireForm";
import Loading from "../Assets/Animation/Loading";

function StudioPortfolio() {
  let hostname = window.location.hostname;
  const parts = hostname?.split(".");
  const studio =
    parts?.length > 2 ? parts?.slice(0, parts?.length - 2).join(".") : null;

  const [studioData, setStudioData] = useState({});
  const [pageLoading, setPageLoading] = useState(false);
  const navigate = useNavigate();
  const [stages, setStages] = useState("Showcase");
  const [enquirePopup, setEnqirePopup] = useState(false);

  useEffect(() => {
    getStudios();
  }, []);

  const backIcon = (innerWidth) => {
    if (innerWidth <= 768) {
      return backMobile;
    } else {
      return backWeb;
    }
  };

  const getStudios = async () => {
    try {
      setPageLoading(true);
      const data = await axios.get(
        `https://storage.googleapis.com/studio_portfolio/${studio}/portfolio.json`
      );
      setStudioData(data?.data);
      setPageLoading(false);
    } catch (err) {
      setPageLoading(false);

      console.log(err);
    }
  };

  return (
    <div>
      {pageLoading && <Loading />}
      {enquirePopup && (
        <EnquireForm
          setPopup={setEnqirePopup}
          cover={studioData?.studioCoverImage}
          studioId={studioData?.studioId}
        />
      )}
      <div className="pb-[60px]">
        <div className="relative">
          <img
            src={studioData?.studioCoverImage}
            alt=""
            className="w-full h-[130px] md:h-[266px] object-cover z-0 "
          />
          <div className=" w-full flex px-[16px] md:px-[182px] items-center justify-between absolute top-0 mt-[53px] md:mt-[60px] ">
            {/* <img
              src={backIcon(window.innerWidth)}
              alt=""
              onClick={() => {
                navigate("/");
              }}
            /> 
            USE THIS WHEN BACK IS REQUIRED
            */}
          </div>
        </div>
        <div className="px-[12px] md:px-[182px]">
          <div className=" flex justify-between items-end mt-[-21px] md:mt-[-89px] mb-[10px] md:mb-[20px] relative ">
            <div>
              <img
                src={studioData?.studioProfilePicture}
                alt=""
                className="bg-white w-[60px] md:w-[152px] h-[60px] md:h-[152px] object-contain border-[3px] border-white drop-shadow-md"
              />
            </div>
            <button
              className="bg-black text-white px-[20px] py-[10px] md:px-[22px] md:py-[12px] rounded-[60px] text-[10px] md:text-[14px] font-semibold"
              onClick={() => setEnqirePopup(true)}
            >
              Contact Us
            </button>
          </div>
          <div className="flex flex-col gap-[2px] md:gap-[6px] mb-[17px] md:mb-[10px]">
            <div className="flex gap-[6px] ">
              <p className="text-black text-[20px] md:text-[24px] font-semibold ">
                {studioData?.studioName}
              </p>
              {studioData?.studioVerified && <img src={verified} alt="" />}
            </div>
            <p className="text400 text-[14px] md:text-[16px] opacity-[0.6]  ">
              {studioData?.studioLocation}
            </p>
          </div>
          <div className="flex flex-col gap-[6px] mb-[30px]">
            <p className="text400 text-[14px] md:text-[16px] max-w-[284px] md:max-w-[600px]">
              {studioData?.studioShortDescription}
            </p>
            <div className="flex gap-[10px]">
              <div className="flex items-center gap-[1px] ">
                <img src={symbol} alt="" />
                <p className="  text-[12px] md:text-[14px]  font-normal text-[#56636F]">
                  {studioData?.studioTitle}
                </p>
              </div>
              {studioData?.studioWebsite && (
                <div className="flex items-center gap-[1px] ">
                  <img src={link} alt="" />
                  <a href={studioData?.studioWebsite} target="_blank">
                    <p className=" text-[12px] md:text-[14px]  font-normal text-[#4692DA]">
                      {studioData?.studioWebsite}
                    </p>
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-[40px] md:gap-[60px]  text600 text-[14px] md:text-[16px] leading-[19px] mb-[16px] md:mb-[20px] border-b-[0.5px] border-b-[#D9D9D9]">
            <div className="flex flex-col gap-[10px] items-center w-[70px] md:w-[82px]  ">
              <p
                onClick={() => {
                  setStages("Showcase");
                }}
                className="cursor-pointer"
              >
                Showcase
              </p>
              {stages === "Showcase" && (
                <div className="w-full h-[3px] bg-black rounded-[10px] "></div>
              )}
            </div>
            {!isEmpty(studioData?.about) && (
              <div className="flex flex-col gap-[10px] items-center w-[70px] md:w-[82px] ">
                <p
                  onClick={() => {
                    setStages("About");
                  }}
                  className="cursor-pointer"
                >
                  About
                </p>
                {stages === "About" && (
                  <div className="w-full h-[3px] bg-black rounded-[10px]"></div>
                )}
              </div>
            )}
            {!isEmpty(studioData?.faq) && (
              <div className="flex flex-col gap-[10px] items-center w-[70px] md:w-[82px] ">
                <p
                  onClick={() => {
                    setStages("FAQs");
                  }}
                  className="cursor-pointer"
                >
                  FAQs
                </p>
                {stages === "FAQs" && (
                  <div className="w-full h-[3px] bg-black rounded-[10px]"></div>
                )}
              </div>
            )}
            {!isEmpty(studioData?.reviews) && (
              <div className="flex flex-col gap-[10px] items-center w-[70px] md:w-[82px] ">
                <p
                  onClick={() => {
                    setStages("Reviews");
                  }}
                  className="cursor-pointer"
                >
                  Reviews
                </p>
                {stages === "Reviews" && (
                  <div className="w-full h-[3px] bg-black rounded-[10px]"></div>
                )}
              </div>
            )}
          </div>
          <div className={styles.seperator}></div>
          {stages === "Showcase" && <StudioShowcase studioData={studioData} />}
          {stages === "About" && <StudioAbout data={studioData?.about} />}
          {stages === "FAQs" && !isEmpty(studioData?.faq) && (
            <StudioFAQ data={studioData?.faq} />
          )}
          {stages === "Reviews" && !isEmpty(studioData?.reviews) && (
            <StudioReviews data={studioData?.reviews} />
          )}
        </div>
      </div>
      <StickyFooter trigger={stages} loading={pageLoading} />
    </div>
  );
}

export default StudioPortfolio;
