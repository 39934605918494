import React, { useState, createContext, useEffect } from "react";
import {
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
  signInAnonymously,
} from "firebase/auth";
import { authentication } from "../store/firebase-config";
import Lottie from "../Assets/Animation/Loading";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState();
  const [userIdToken, setUserIdToken] = useState();
  const [isGuestUser, setIsGuestUser] = useState(false);
  const deployment = process.env.REACT_APP_DEPLOYMENT;
  const verifyUser = async () => {
    try {
      const idToken = await authentication.currentUser.getIdToken(true);
      setUserIdToken(idToken);
      return idToken;
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authentication, (user) => {
      setCurrentUser(user);
      setPageLoading(false);
    });
    return unsubscribe;
  }, []);

  const googleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      setLoading(true);
      const login = await signInWithPopup(authentication, provider);
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_LOGIN}`,
        headers: {
          Authorization: `Bearer ${login._tokenResponse.idToken}`,
          "Content-Type": "application/json",
        },
      };
      const loginCheck = await axios(config);
      if (loginCheck.status === 201) {
        navigate(`/aiphotosortingdemo`);
      } else {
        setLoading(false);
        navigate(`/login`);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      navigate(`/login`);
    }
  };

  const guestSignIn = async () => {
    try {
      setLoading(true);
      const login = await signInAnonymously(authentication);
      console.log("login", login);
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_LOGIN}`,
        headers: {
          Authorization: `Bearer ${login._tokenResponse.idToken}`,
          "Content-Type": "application/json",
        },
      };
      const loginCheck = await axios(config);
      console.log("loginCheck", loginCheck);
      if (loginCheck.status === 201) {
        navigate(`/aiphotosortingdemo`);
      } else {
        setLoading(false);
        navigate(`/login`);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      navigate(`/login`);
    }
  };

  const googleSignOut = async () => {
    signOut(authentication)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <userAuthContext.Provider
      value={{
        googleSignIn,
        guestSignIn,
        currentUser,
        loading,
        googleSignOut,
        verifyUser,
        userIdToken,
        isGuestUser,
        deployment,
      }}
    >
      {pageLoading ? <Lottie /> : children}
    </userAuthContext.Provider>
  );
}
