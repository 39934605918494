import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../Components/NavbarNew';
import styles from './Css/facedetection.module.css'
import { useNavigate } from 'react-router-dom'
import coverImage from '../Assets/Images/Demo/demoLandingCover.png'
import qrImage from "../Assets/Images/Demo/demoQr.png";
import videoCover from "../Assets/Images/Demo/demoVideoCover.png";
import playButton from "../Assets/Images/Demo/demoPlayButton.svg";
import axios from 'axios';
import { userAuthContext } from '../Context/UserAuthContext';
import Lottie from '../Assets/Animation/Loading'
import ErrorPopup from "../Components/Popups/ErrorPopup/ErrorPopup";
import { PopupWrapper } from "../UI/PopupWrapper/PopupWrapper";



function FaceDetectionDemoLanding() {
    const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({ "errorStatus": false, errorMessage: "", header: "" })
    const tokenData = userIdToken || currentUser?.accessToken


    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const handleCreateFolder = async () => {
        try {
            verifyUser()
            setLoading(true)
            let url = `${process.env.REACT_APP_CREATE_SESSION}`
            let config = {
                method: 'post',
                url: url,
                headers: {
                    Authorization: `Bearer ${tokenData}`,
                    'Content-Type': 'application/json',
                },
            }
            const response = await axios(config)
            if (response.status === 200) {
                if (response?.data?.error) {
                    setError({ errorMessage: response?.data?.msg, errorStatus: true, header: "Message" })
                    setLoading(false)
                }
                else {
                    setError({ errorMessage: response?.data?.msg, errorStatus: false, header: "Message" })
                    setLoading(false)
                    navigate(`/aiphotosortingdemo/${response?.data?.folderId}`)
                }
            }
        } catch (error) {
            console.log('error', error)
            setLoading(false)
        }
    };
    return (
        <div className='containFull bg-[#fff] '>
            <Navbar />
            {loading ? (
                <>
                    <Lottie />
                </>
            ) : (
                <div className='flex flex-col items-center px-[20px] lg:px-[80px]'>
                    {error.errorStatus === true && (
                        <PopupWrapper visible={error.errorStatus}>
                            <ErrorPopup error={error} setError={setError} />
                        </PopupWrapper>
                    )}
                    <div className={styles.container}>
                        <div className='w-full mt-[24px] flex flex-col items-start'>
                            <p className="font_semibold_24">Experience the AI Photo Delivery Demo</p>
                        </div>
                        <div className={styles.mainDiv}>
                            <div className={styles.childDiv1}>
                                <div className={`${styles.stepsDiv} no-scrollbar`}>
                                    <div className={styles.item1}>
                                        <div className="flex justify-between">
                                            <p className="font_semibold_16">Step 01</p>
                                            <img src={qrImage} alt="" />
                                        </div>
                                        <p className="font_semibold_21_18 mt-[16px] lg:mt-0">
                                            Register your face to get your photos
                                        </p>
                                    </div>
                                    <div className={styles.item2}>
                                        <p className="font_semibold_16">Step 02</p>
                                        <p className="font_semibold_21_18">
                                            Upload Photos as a photographer
                                        </p>
                                    </div>
                                    <div className={styles.item3}>
                                        <p className="font_semibold_16">Step 03</p>
                                        <p className="font_semibold_21_18">
                                            Your Photos are getting scanned for photo detection
                                        </p>
                                    </div>
                                    <div className={styles.item4}>
                                        <p className="font_semibold_16">Step 04</p>
                                        <p className="font_semibold_21_18">
                                            You will recieve your photos in registerd method , alternate you can Scan your face to get photos where you are in from the uploaded photos
                                        </p>
                                    </div>
                                    <div className={styles.item5}>
                                        <img src={videoCover} alt="" className='w-full   object-cover' />
                                        <div className='absolute bottom-[20px] left-[20px] flex items-center gap-[10px]'>
                                            <img src={playButton} alt="" className='' />
                                            <p className="font_semibold_16 text-[#fff]">Watch Now</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.childDiv2}>
                                <div
                                    onClick={handleCreateFolder}
                                    className="relative cursor-pointer">
                                    <img
                                        src={coverImage}
                                        alt=""
                                        className="h-full w-full max-w-[425px] object-cover rounded-[24px] "
                                    />
                                    <div className={styles.absoluteDiv}>
                                        <p className="font_medium_16 ">
                                            Experience the magic of memshots  now.
                                        </p>
                                        <button className={styles.startDemoButton}>Start Demo</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
        </div>
    )

}

export default FaceDetectionDemoLanding