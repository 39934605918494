import React, { useState } from 'react';
import PricingPopup from '../Components/PricingCalculator/PricingPopup';
import Pricing from '../Assets/pricing.json';
import PricingList from '../Components/PricingLists/PricingLists';

const PricingCalculator = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [pricingData, setPricingData] = useState([]);
  const [uniqueIdCounter, setUniqueIdCounter] = useState(1);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedConfig, setSelectedConfig] = useState(null);

  const handleSelectOption = (option) => {
    const config = Pricing[option];
    setSelectedOption(option);
    setSelectedConfig(config);
  };

  const titleKey = (key) => {
    console.log("key", key);
    let titleShow = null;
    if (key === "faceDetection") {
      titleShow = "Face Detection";
    } else if (key === "selectionTool") {
      titleShow = "Selection Tool";
    } else if (key === "album") {
      titleShow = "Photo Album";
    } else {
      titleShow = "Default";
    }
    return titleShow;
  };

  const handleAddItem = async () => {
    if (selectedOption && selectedConfig) {
      const newItem = {
        id: uniqueIdCounter,
        option: selectedOption,
        ...configMaker(selectedOption)
      };
      setUniqueIdCounter(uniqueIdCounter + 1);
      setPricingData((prevPricingData) => [...prevPricingData, newItem]);
    }

    setPopupOpen(false);
  };

  const configMaker = (selectedOption) => {
    let initialData = {};
    let totalPrice = 0
    Object.entries(Pricing[selectedOption]).forEach(([key, value]) => {

      if (value?.type === "textbox") {
        totalPrice = totalPrice + parseInt(value?.default) * parseInt(value?.price)
        initialData[key] = {
          "value": value?.default,
          "type": "textbox",
          "title": value?.title,
        };
      }
      if (value?.type === "dropdown") {
        const selectedOptionValue = value?.optionValues.find(
          (option) => option.value === value?.default
        );
        totalPrice = totalPrice + parseInt(selectedOptionValue?.price)
        initialData[key] = {
          "value": value?.default,
          "type": "dropdown",
          "title": value?.title,
        };
      }
    });
    return { ...initialData, totalPrice };
  };

  const totalPriceCalculator = (selectedOption, item) => {
    let totalPrice = 0
    Object.entries(Pricing[selectedOption]).forEach(([key, value]) => {
      if (value?.type === "textbox") {
        totalPrice = totalPrice + parseInt(value?.price) * parseInt(item[key]?.value)
      }
      if (value?.type === "dropdown") {
        const selectedOptionValue = value?.optionValues.find(
          (option) => option.value === item[key]?.value
        );
        totalPrice = totalPrice + parseInt(selectedOptionValue?.price)
      }

    });
    return totalPrice
  }
  const handleInputChange = (field, value, pricedItem) => {
    setPricingData((prevPricingData) =>
      prevPricingData.map((item) =>
        item.id === pricedItem?.id
          ? {
            ...item,
            [field]: {
              ...item[field],
              value: value,
            },
            totalPrice: totalPriceCalculator(item?.option, {
              ...item,
              [field]: {
                ...item[field],
                value: value,
              },
            }),
          }
          : item
      )
    );
  };
  const handleDeleteItem = (id) => {
    setPricingData((prevPricingData) =>
      prevPricingData.filter((item) => item.id !== id)
    );
  };

  const renderInputField = (field, config, pricedItem) => {
    switch (config.type) {
      case 'textbox':
        return (
          <input
            key={field}
            type="number"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
            placeholder={config.title}
            value={parseInt(pricedItem[field]?.value) || ''}
            onChange={(e) => handleInputChange(field, e.target.value, pricedItem)}
          />
        );
      case 'dropdown':
        return (
          <select
            key={field}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
            value={pricedItem[field]?.value || ''}
            onChange={(e) => handleInputChange(field, e.target.value, pricedItem)}
          >
            {Pricing[pricedItem?.option][field]?.optionValues.map((option) => (
              <option key={option.value} value={option.value}>
                {option.value}
              </option>
            ))}
          </select>
        );
      default:
        return null;
    }
  };

  const totalPrice = pricingData.reduce(
    (acc, data) => acc + (data ? data.totalPrice : 0),
    0
  );
  
  console.log("pricingData", pricingData);
  return (
    <div className="min-h-screen bg-[B4D4FF]">
      <nav className="bg-white p-4 flex justify-center">
        <button
          className="bg-[#B80000] text-white px-4 py-2 rounded hover:bg-blue-600 bott"
          onClick={() => setPopupOpen(true)}
        >
          Add To Estimate
        </button>
      </nav>
      <div className="flex">
        <div className="flex-1 ">
          <div className="flex items-center justify-center flex-col mt-[50px]">
            {pricingData.map((pricedItem) => (
              <div
                key={pricedItem.id}
                className="mb-4 bg-[#7FC7D9] p-6 rounded-lg shadow-md w-80  bg-[#176B87]"
              >
                <h3 className="text-lg text-black font-semibold mb-2">
                  {titleKey(pricedItem?.option)}
                </h3>
                {Object.entries(pricedItem).map(([field, fieldConfig]) => (
                  field !== 'id' && field !== 'option' && (
                    <div key={field} className="mb-2">
                      <label className="block mb-2 text-black">
                        {fieldConfig.title}
                      </label>
                      {renderInputField(field, fieldConfig, pricedItem)}
                    </div>
                  )
                ))}
                <div className="mb-2">
                  <label className="block mb-2 text-black">
                    Total Price for {titleKey(pricedItem?.option)}
                  </label>
                  <div className="text-black font-semibold">{`${pricedItem?.totalPrice}`}</div>
                </div>
              </div>
            ))}

            {isPopupOpen && (
              <PricingPopup
                setPopupOpen={setPopupOpen}
                selectedOption={selectedOption}
                handleSelectOption={handleSelectOption}
                handleAddItem={handleAddItem}
                titleKey={titleKey}
              />
            )}
          </div>
        </div>
        {pricingData.length > 0 && (
          <div className="flex-1 p-4 mt-[100px] bg-[#EEF5FF] mr-[100px] rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Cost Details</h2>
            <PricingList
              pricingData={pricingData}
              titleKey={titleKey}
              totalPrice={totalPrice}
              handleDeleteItem={handleDeleteItem}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PricingCalculator;
