import React, { useState } from "react";
import styles from "./ImageGallery.module.css";
import Popup from "../Popup/Popup";

const ImageGallery = ({ data, reference, hasMore, setItemsCount }) => {
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [loadedImages, setLoadedImages] = useState([]);
  const originalArray = data;

  const calculateNumArrays = () => {
    return window.innerWidth < 768 ? 2 : 4;
  };
  const numArrays = calculateNumArrays();
  const splitArrays = Array.from({ length: numArrays }, () => []);

  for (let i = 0; i < originalArray?.length; i++) {
    const arrayIndex = i % numArrays;
    splitArrays[arrayIndex].push(originalArray[i]);
  }
  const handleShowPopup = (files) => {
    let index = data?.indexOf(files);
    setCurrentIndex(index);
    setClickedImg(files);
  };

  const handelRotationRight = () => {
    const totalLength = data?.length;

    if (currentIndex + 3 >= totalLength) {
      if (hasMore) {
        setItemsCount((prev) => prev + 8);
      }
    }
    let newIndex = currentIndex + 1;
    if (newIndex === totalLength) {
      newIndex = 0;
    }
    const newUrl = data?.filter((item) => {
      return data?.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0];

    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  const handelRotationLeft = () => {
    const totalLength = data?.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = data && data[totalLength - 1];
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = data?.filter((item) => {
      return data?.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0];

    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };
  const isPrevDisabled = currentIndex === 0;
  const isNextDisabled = currentIndex === data?.length - 1 && !hasMore;

  const handleImageLoad = (url) => {
    setLoadedImages((prev) => [...prev, url]);
  };
  return (
    <>
      {clickedImg && (
        <Popup
          clickedImg={clickedImg}
          setClickedImg={setClickedImg}
          handelRotationLeft={handelRotationLeft}
          handelRotationRight={handelRotationRight}
          isNextDisabled={isNextDisabled}
          isPrevDisabled={isPrevDisabled}
        />
      )}
      <div className={`${styles.imageGallery} mt-8`}>
        {splitArrays?.map((splitArray, index) => (
          <div key={index} className={styles.image}>
            {splitArray?.map((obj, itemIndex) => (
              <div
                onClick={() => handleShowPopup(obj)}
                ref={index === splitArrays?.length - 1 ? reference : null}
              >
                <div className="w-full my-[4px] relative cursor-pointer">
                  {!loadedImages.includes(obj?.url) && (
                    <div className="w-full h-[200px] bg-gray-200 animate-pulse"></div>
                  )}
                  <img
                    src={obj?.url}
                    onLoad={() => handleImageLoad(obj?.url)}
                    className={`w-full h-full object-cover ${
                      loadedImages.includes(obj?.url) ? "block" : "hidden"
                    }`}
                    alt={obj?.participantName}
                  />
                  <div className="text-white absolute top-0 left-0 w-[100%] h-[100%] flex flex-col justify-between  p-[8px] text-smallest top-and-bottom-gradient">
                    <div className=" flex items-center justify-between"></div>
                    <div className=" flex items-center justify-between">
                      <p className="truncate max-w-[80%]">
                        {obj?.participantName}
                      </p>
                      {obj.fileType === "video" && (
                        <div className={styles.playbtnBg}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="32"
                            viewBox="0 0 26 32"
                            fill="none"
                          >
                            <path
                              d="M1.99339 30.8984C2.30915 31.0344 2.66445 31.125 3.01974 31.125C3.57238 31.125 4.12503 30.8984 4.59866 30.5358L24.1382 18.3425L24.3355 18.1159C24.8487 17.5266 25.125 16.8013 25.125 15.9855C25.125 15.1696 24.8487 14.399 24.3355 13.8551L24.1382 13.6284L4.59866 1.34452C3.88814 0.709954 2.8618 0.573977 1.99339 0.981912C1.00652 1.43517 0.375 2.5684 0.375 3.79228V28.0881C0.374935 29.312 1.00652 30.4451 1.99339 30.8984Z"
                              fill="#FFFEFE"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default ImageGallery;
