import React, { useContext, useEffect, useState } from "react";
import Navbar from "../Components/NavbarNew";
import styles from "./Css/facedetection.module.css";
import axios from "axios";
import { userAuthContext } from "../Context/UserAuthContext";
import { useNavigate, useParams } from "react-router-dom";
import FaceScanning from "../Assets/Animation/FaceScanning";
import whatsappLogo from "../Assets/Images/Demo/whatsappLogo.svg";
import gmailLogo from "../Assets/Images/Demo/gmailLogo.svg";
import guidelinesPhotographerImage from "../Assets/Images/Demo/guidelinesPhotographerImage.jpg";
import GuidlinesDemo from "../Components/GuidlinesDemo";
import closeIcon from '../Assets/Images/Demo/closeIcon.svg'

function FaceDetectionDemoScanning() {
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  let { folderId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [sessionData, setSessionData] = useState({});
  const tokenData = userIdToken || currentUser?.accessToken;
  const memshotAlbumId = parseInt(process.env.REACT_APP_MEMSHOTSALBUMID);


  return (
    <div className="containFull bg-[#fff] ">
      <Navbar />
      <div className="flex flex-col items-center px-[20px] lg:px-[80px]">
        <div className="w-full max-w-[1280px]">
          <div className="w-full py-[24px] lg:py-[18px] flex justify-between ">
            <div className={styles.attendeeButton}>Photographer</div>
            <p className="font_semibold_24 text-[#000] w-full text-center">
              Experience the  AI Photo Delivery Demo
            </p>
            <div className="w-[110px] hidden lg:block"></div>
          </div>
          <div className="mt-[28px] lg:mt-[20px] flex justify-center">
            <div className={styles.multiplePhotosContainer}>
              <div className=" absolute left-[-30px] w-[130px]  transform -rotate-45  bg-[#497FFF]  py-[3px]">
                <p className="font_medium_13 text-[#F6F6F6] text-center">
                  Photographer
                </p>
              </div>
              <div
                onClick={() => navigate("/")}
                className="absolute right-[20px] top-[20px]">
                <img src={closeIcon} alt="" className="closeIconShadow rounded-full cursor-pointer" />
              </div>
              <div className="w-full h-full min-h-[540px] lg:min-h-[624px] flex flex-col justify-center items-center">
                <FaceScanning />
                <p className="font_medium_16 text-[#4A80FF] mt-[14px]">
                  Ai recognizing the faces...
                </p>

                <p className="font_medium_16 text-[#161616] mt-[22px] text-center max-w-[340px]">
                  Your training is taking place in the background. <br /> It will take upto 10 minutes. <br /> You can close the window.
                </p>
                <div className="horizontalSeperator my-[30px] max-w-[320px]"></div>
                <p className="font_normal_13 text-[#161616] opacity-[0.6] text-center max-w-[270px]">
                  You will receive the sorted album through WhatsApp or
                  Email, as you have provided.
                </p>
                <div className="flex gap-[20px] items-center mt-[22px]">
                  <img src={whatsappLogo} alt="" />
                  <img src={gmailLogo} alt="" />
                </div>
                <button
                  onClick={() => navigate("/")}
                  className="font_medium_13 opacity-[0.6] mt-[20px] lg:mt-[40px] underline cursor-pointer"
                >
                  Back to home
                </button>
              </div>
            </div>
          </div>
          <GuidlinesDemo image={guidelinesPhotographerImage} />
        </div>
      </div>
    </div>
  );
}

export default FaceDetectionDemoScanning;
