import React, { useContext, useEffect, useState } from "react";
import backMobile from "../../Assets/Images/studioBackMobile.svg";
import backWeb from "../../Assets/Images/studioBackWeb.svg";
import tick from "../../Assets/Images/successTick.svg";
import DynamicInput from "../DynamicInput/DynamicInput";
import * as _ from "lodash";
import axios from "axios";
import { userAuthContext } from "../../Context/UserAuthContext";
import styles from "./EnquireForm.module.css";

export default function EnquireForm({ setPopup, cover, studioId }) {
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [leadsSubmitted, setLeadsSubmitted] = useState(false);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    verifyUser();
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const backIcon = (innerWidth) => {
    if (innerWidth <= 768) {
      return backMobile;
    } else {
      return backWeb;
    }
  };
  let field = [
    {
      name: "Name",
      type: "text",
      id: "name",
    },
    {
      name: "Email",
      type: "text",
      id: "email",
    },
    {
      name: "Phone",
      type: "text",
      id: "phone",
    },
    {
      name: "Requirement",
      type: "TEXTAREA",
      id: "requirment",
    },
  ];

  const handleChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const handleSubmit = async (e) => {
    let validateResponse = validate(formValues);
    setFormErrors(validateResponse);

    if (
      _.isEmpty(validateResponse) &&
      formValues.name &&
      formValues.requirment
    ) {
      submitFormApi();
    }
  };
  const validate = (values) => {
    const errors = {};
    if (!values?.name) {
      errors.name = "This field is required";
    }
    if (!values.requirment) {
      errors.requirment = "This field is required  ";
    }

    return errors;
  };

  const submitFormApi = async () => {
    setLoading(true);
    try {
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ENQUIRE}/${studioId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: formValues,
      };
      const response = await axios(config);
      if (response?.data?.status == "success") {
        setLeadsSubmitted(true);
      }
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div className="w-[100%] h-[100%] fixed top-0 flex flex-col items-center justify-center serviceformbg backdrop-blur-xl z-[50] overflow-x-hidden overflow-y-auto no-scrollbar">
      <div className=" absolute top-0 px-[3%] w-full">
        <div className="h-auto  box w-full w-full max-w-[800px]  bg-[#FFFFFF] rounded-[20px] my-10 mx-auto drop-shadow-lg overflow-hidden">
          <div
            className="relative w-full flex items-start justify-between md:h-[200px] h-[196px]  rounded-[8px] md:rounded-[2px] overflow-hidden p-[16px]"
            style={{
              background: `url("${cover}")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div
              className="absolute inset-0 w-full h-full z-10 rounded-[8px] md:rounded-[2px]"
              style={{
                background:
                  "linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%)",
              }}
            />
            <div className="absolute inset-0 w-full h-full flex items-start justify-between gap-[16px] text-center z-20 p-[16px]">
              <button
                className="cursor-pointer "
                onClick={() => {
                  setPopup(false);
                }}
                disabled={loading}
              >
                <img src={backIcon(window.innerWidth)} alt="" />
              </button>
              <div className="text-white w-full h-full flex flex-col justify-center">
                <p
                  className={`${styles.dmSerifFont} mb-[4px] md:mb-[6px]  text-[16px] md:text-[22px] leading-[22px] md:leading-[30px]`}
                >
                  Contact Us
                </p>
              </div>
              <div className="min-w-[32px]" />
            </div>
          </div>
          {leadsSubmitted ? (
            <div className="text600 w-full min-h-[30vh] flex flex-col gap-[18px] items-center justify-center text-center p-[16px]">
              <img src={tick} alt="" />
              <p>Request successfully submitted. We'll get back to you soon!</p>
            </div>
          ) : (
            <div className="p-[16px]">
              {field?.map((field) => {
                return (
                  <DynamicInput
                    handleChange={handleChange}
                    value={formValues[field?.id]}
                    error={formErrors[field?.id]}
                    field={field}
                  />
                );
              })}
              <div className="flex justify-end">
                <button
                  className="bg-black text-white px-[20px] py-[10px] md:px-[22px] md:py-[12px] rounded-[60px] text-[10px] md:text-[14px] font-semibold"
                  onClick={handleSubmit}
                >
                  {loading ? "Submiting.." : "Submit Request"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
