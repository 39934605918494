import React, { useEffect, useState } from "react";
import NavbarNew from '../Components/NavbarNew'
import axios from "axios";
import jobsPostIcon from '../Assets/Images/jobsPostIcon.svg'
import jobsLocationIcon from '../Assets/Images/jobsLocationIcon.svg'
import Footer from "../Components/Footer";
import NavbarBottom from "../Components/NavbarBottom";


function Jobs() {
    const [fliterSelected, setFilterSelected] = useState("Wedding")
    const [jobsData, setJobsData] = useState({})

    useEffect(() => {
        getJobs("Wedding")
        window.scroll(0, 0)
    }, [])

    const getJobs = async (filterValue) => {
        try {
            const data = await axios.get(`/Json/Jobs/${filterValue}.json`)
            setJobsData(data?.data)
        } catch (err) {
            console.log(err);
        }
    }
    
    return (
        <div>
            <NavbarNew />
            <div className='w-full h-full min-h-[70vh] px-[5.26%] xl:px-[8.33%] mb-[60px]'>
                <div className='pt-[12px] w-full  pb-[16px] lg:pb-[24px] flex items-center lg:justify-center  gap-[16px] mx-auto overflow-y-hidden overflow-x-auto no-scrollbar sticky top-[83px] lg:top-[97px] z-40  bg-[#FFF]'>
                    <button
                        onClick={() => {
                            setFilterSelected("Wedding")
                            getJobs("Wedding")
                        }}
                        className={` ${fliterSelected === 'Wedding' ? "bg-[#8B34FD] text-[#FFFFFF]" : "bg-[#F8F8F8] text-[#161616]"} filterChip  lg:hover:bg-[#8B34FD]  lg:hover:text-[#FFFFFF] font_medium_12 `}
                    >
                        Wedding
                    </button>
                    <button
                        onClick={() => {
                            setFilterSelected("Engagement")
                            getJobs("Engagement")
                        }}
                        className={` ${fliterSelected === 'Engagement' ? "bg-[#8B34FD] text-[#FFFFFF]" : "bg-[#F8F8F8] text-[#161616]"} filterChip  lg:hover:bg-[#8B34FD]  lg:hover:text-[#FFFFFF] font_medium_12 `}
                    >
                        Engagement
                    </button>
                    <button
                        onClick={() => {
                            setFilterSelected("Reception")
                            getJobs("Reception")
                        }}
                        className={` ${fliterSelected === 'Reception' ? "bg-[#8B34FD] text-[#FFFFFF]" : "bg-[#F8F8F8] text-[#161616]"} filterChip  lg:hover:bg-[#8B34FD]  lg:hover:text-[#FFFFFF] font_medium_12 `}
                    >
                        Reception
                    </button>
                    <button
                        onClick={() => {
                            setFilterSelected("Corporate Events")
                            getJobs("Corporate Events")
                        }}
                        className={` ${fliterSelected === 'Corporate Events' ? "bg-[#8B34FD] text-[#FFFFFF]" : "bg-[#F8F8F8] text-[#161616]"} filterChip  lg:hover:bg-[#8B34FD]  lg:hover:text-[#FFFFFF] font_medium_12 `}
                    >
                        Corporate Events
                    </button>
                </div>
                <div className="w-full h-full flex flex-col items-center text-center">
                    <p className="font_semibold_40_30 text-[#000] mt-[16px] lg:mt-[24px] max-w-[595px]">
                        We’ve got jobs that's perfect for you.
                    </p>
                    <p className="font_medium_16_14 text-[#161616] mt-[16px] lg:mt-[24px] max-w-[563px]">
                        Jobs for your personal and businesses at every stage of growth.
                    </p>
                </div>
                <div className="w-full flex justify-between items-center mt-[24px]">
                    <p className="font_semibold_16_14  lg:mt-[30px] text-[#000]">{jobsData?.jobs?.length} {fliterSelected} Jobs</p>
                    <div className="flex items-center px-[24px] py-[10px] rounded-[50px] bg-black text-[#fff] gap-[6px] hover:opacity-[0.8] cursor-pointer">
                        <img src={jobsPostIcon} alt="" />
                        <p className="font_medium_16_14">Post a job</p>
                    </div>
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-3 gap-[16px] mt-[24px] lg:mt-[32px]'>
                    {
                        jobsData?.jobs?.map((obj, i) => {
                            return (
                                <div key={i}
                                    className="boxShadow p-[12px] cursor-pointer hover:opacity-[0.8]">
                                    <div className="flex items-center gap-[7px] lg:gap-[12px]">
                                        <img src={obj?.CompanyIcon} alt="" className="w-[40px] h-[40px] lg:w-[60px] lg:h-[60px] rounded-[5px] object-cover" />
                                        <div className="flex flex-col  gap-[4px]">
                                            <p className="font_medium_16_14 text-[#000]">{obj?.CompanyName}</p>
                                            <div className="flex items-center">
                                                <img src={jobsLocationIcon} alt="" className="w-[18px] h-[18px]" />
                                                <p className="font_normal_13 text-[#161616] opacity-[0.6] ">{obj?.PreferredLocation}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-[12px] min-h-[90px]">
                                        <p className="font_semibold_21_18 text-[#161616]">{obj?.JobRole}</p>
                                        <p className="font_medium_16_14 text-[#000] mt-[6px]">{obj?.JobDescription}</p>
                                    </div>
                                    <div className="font_normal_13 text-[#161616] opacity-[0.6]">{obj?.timeTillPost}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Footer />
            <NavbarBottom />
        </div>
    );
}

export default Jobs;
