import React from 'react'
import aiCover from '../../Assets/Images/aiCover.png'

function Cover() {
    return (
        <div className='w-full h-full pt-[44px] xl:pt-[116px] xl:px-[8.33%] mb-[60px] xl:mb-[100px]'>
            <div className='flex flex-col xl:grid xl:grid-cols-2 items-center'>
                <div className='px-[5.26%] mb-[60px] xl:mb-0'>
                    <p className='mb-[16px] xl:mb-[27px] max-w-[519px] font-semibold text-[30px] xl:text-[40px] leading-[40px] xl:leading-[48px] tracking-[-0.6px] xl:tracking-[-0.8px] '>AI Powered shootmates tools for Event Planners & Photographers</p>
                    <div className='flex gap-[7px] items-center'>
                        <div className='rounded-full bg-[#2087FF] w-[11px] h-[11px]'></div>
                        <p className='font-semibold text-[13px] text-[#2087FF] leading-[18px] tracking-[-0.26px]'>Al-powered Galleries</p>
                    </div>
                </div>
                <div>
                    <img src={aiCover} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Cover