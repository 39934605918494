import React from "react";

function StudioAbout({ data }) {
  return (
    <div className="mb-[20px]">
      <p className="text400 text-[14px] md:text-[16px] mb-[30px]">
        {data?.aboutContent}
      </p>
      <p className="mb-[10px] text600 text-[14px] md:text-[16px]">
        {data?.aboutTitle1}
      </p>
      <p className="mb-[6px] text400 text-[14px] md:text-[16px]">
        {data?.aboutContent1}
      </p>
      <div className="my-[20px]">
        {data?.aboutItemsOffered?.map((obj, index) => {
          return (
            <div className="flex gap-[10px] items-center ml-[8px] md:ml-[16px]">
              <div className="bg-black rounded-full w-[5px] h-[5px]"></div>
              <p className="text400 text-[14px] md:text-[16px]">{obj?.list}</p>
            </div>
          );
        })}
      </div>
      <p className="mb-[10px] text-[#000000] text-[14px] font-normal leading-[19px] opacity-[0.6]">
        {data?.aboutTitle2}
      </p>
      <p className="mb-[30px] text-[#4692DA] text-[12px] font-normal leading-[19px]">
        {data?.aboutContent2}
      </p>
    </div>
  );
}

export default StudioAbout;
