import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Loading from "../Assets/Animation/Loading";
const PaymentThirdParty = () => {
  let data = useLocation();
  const transactUrl = data?.search?.split("?gatewayresp=")[1];
  useEffect(() => {
    window.location.href = decodeURIComponent(transactUrl);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Loading />
    </div>
  );
};
export default PaymentThirdParty;
