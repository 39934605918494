import React, { useEffect } from "react";
import styles from "./styles/home.module.css";
import close from "../Assets/Images/closeIcon.svg";
import ReactPlayer from "react-player";

function VideoPopup({ setPopup, urlSelected }) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  return (
    <div className={styles.popup}>
      <div className="flex  top-[20px] right-[20px]  justify-end items-center absolute z-50">
        <img
          src={close}
          alt=""
          className="w-[20px] md:w-[30px] h-[20px] md:h-[30px] cursor-pointer"
          onClick={() => {
            setPopup(false);
          }}
        />
      </div>
      <div className="flex md:flex-row flex-col justify-center items-center gap-[60px] relative w-[100%] h-[100%]">
        <div className="h-[100%] w-[100%] overflow-hidden flex items-center justify-center">
          <ReactPlayer
            controls={true}
            width="100%"
            height="80%"
            url={urlSelected}
            style={{}}
          />
        </div>
      </div>
    </div>
  );
}

export default VideoPopup;
