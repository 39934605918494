import React, { useEffect, useState } from 'react'
import NavbarNew from '../Components/NavbarNew'
import Footer from '../Components/Footer'
import NavbarBottom from '../Components/NavbarBottom'
import axios from 'axios'

function Community() {
    const [fliterSelected, setFilterSelected] = useState("Photographers")
    const [communityData, setCommunityData] = useState({})



    useEffect(() => {
        getCommunity("Photographers")
        window.scroll(0, 0)
    }, [])

    const getCommunity = async (filterValue) => {
        try {
            const data = await axios.get(`/Json/Community/${filterValue}.json`)
            setCommunityData(data?.data)
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div>
            <NavbarNew />
            <div className='w-full h-full min-h-[70vh] px-[5.26%] xl:px-[8.33%] mb-[60px]'>
                <div className='pt-[12px] w-full  pb-[16px] lg:pb-[24px] flex items-center lg:justify-center  gap-[16px] mx-auto overflow-y-hidden overflow-x-auto no-scrollbar sticky top-[83px] lg:top-[97px] z-40  bg-[#FFF]'>
                    <button
                        onClick={() => {
                            setFilterSelected("Photographers")
                            getCommunity("Photographers")
                        }}
                        className={` ${fliterSelected === 'Photographers' ? "bg-[#8B34FD] text-[#FFFFFF]" : "bg-[#F8F8F8] text-[#161616]"} filterChip  lg:hover:bg-[#8B34FD]  lg:hover:text-[#FFFFFF] font_medium_12 `}
                    >
                        Photographers
                    </button>
                    <button
                        onClick={() => {
                            setFilterSelected("Studios")
                            getCommunity("Studios")
                        }}
                        className={` ${fliterSelected === 'Studios' ? "bg-[#8B34FD] text-[#FFFFFF]" : "bg-[#F8F8F8] text-[#161616]"} filterChip  lg:hover:bg-[#8B34FD]  lg:hover:text-[#FFFFFF] font_medium_12 `}
                    >
                        Studios
                    </button>
                    <button
                        onClick={() => {
                            setFilterSelected("Event Management")
                            getCommunity("Event Management")
                        }}
                        className={` ${fliterSelected === 'Event Management' ? "bg-[#8B34FD] text-[#FFFFFF]" : "bg-[#F8F8F8] text-[#161616]"} filterChip  lg:hover:bg-[#8B34FD]  lg:hover:text-[#FFFFFF] font_medium_12 `}
                    >
                        Event Management
                    </button>
                </div>
                <div className="w-full h-full flex flex-col items-center text-center">
                    <p className="font_semibold_40_30 text-[#000] mt-[16px] lg:mt-[24px] max-w-[595px]">
                        We’ve the perfect community for you.
                    </p>
                    <p className="font_medium_16_14 text-[#161616] mt-[16px] lg:mt-[24px] max-w-[563px]">
                        Community for your personal and businesses at every stage of growth.
                    </p>
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-4 gap-[16px] mt-[24px] lg:mt-[32px]'>
                    {
                        communityData?.community?.map((obj, i) => {
                            return (
                                <div
                                    key={i}
                                    className='relative cursor-pointer hover:opacity-[0.9]'>
                                    <img src={obj?.image} alt="" className='aspect-square object-cover rounded-[12px]' />
                                    <div className='absolute bottom-[10px] left-[16px] text-white'>
                                    <p className='font-semibold text-[14px] leading-[22px] tracking-[-0.28px]' >{obj?.title}</p>
                                    <p className='font-medium text-[13px] leading-[18px]  tracking-[-0.26px]'>{obj?.shortDescription}</p>
                                </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Footer />
            <NavbarBottom />
        </div>
    )
}

export default Community