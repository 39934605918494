import React from 'react'
import guidelinesLink from '../Assets/Images/Demo/guidelinesLink.svg'
import guidelinesWhatsapp from '../Assets/Images/Demo/guidelinesWhatsapp.svg'
import guidelinesGmail from '../Assets/Images/Demo/guidelinesGmail.svg'

function GuidlinesDemo({image}) {
    return (
        <div className='my-[60px] w-full flex flex-col justify-center items-center '>
            <div className='max-w-[667px]'>
                <p className='font_semibold_24 text-center'>Guidelines</p>
                <div className='horizontalSeperator my-[16px] lg:my-[24px]'></div>
                <p className='font_semibold_18'>Scanning QR Code or Clicking URL with your Smart Phone</p>
                <div className='mt-[16px] mb-[40px] lg:mt-[24px] lg:mb-[60px] font_normal_16 '>
                    <p>1. Open the Camera app from the Home Screen, Control Center, or Lock Screen</p>
                    <p>2. Select the rear facing camera. Hold your device so that the QR code appears in the view finder in the Camera app.</p>
                    <p>3. tap the notification to open the link associated with the QR code</p>
                </div>
                <p className='font_semibold_18'>Uploading image or taking selfies</p>
                <p className='my-[24px] font_normal_16 '>Please ensure the image features a face with both eyes open and visible, with the full head and shoulders of the person clearly visible and not obscured. Avoid tightly cropped or obscured images for optimal results</p>
                <img src={image} alt="" />
                <p className='font_semibold_18 mt-[38px] mb-[24px] lg:mt-[60px]'>Receiving photos & videos</p>
                <p className='font_normal_16'>Please ensure the image features a face with both eyes open and visible, with the full head and shoulders of the person clearly visible and not obscured. Avoid tightly cropped or obscured images for optimal results</p>
                <div className='mt-[26px] lg:mt-[42px] w-full flex gap-[24px]  overflow-x-scroll overflow-y-hidden  no-scrollbar'>
                    <div className='rounded-[16px] bg-[#F6F6F6] py-[16px] px-[9px] shrink-0 h-[210px] flex flex-col justify-between'>
                        <div>
                            <img src={guidelinesLink} alt="" />
                            <p className='font_semibold_16 mt-[16px]'>On link</p>
                        </div>
                        <p className='font_semibold_18 max-w-[185px]'>With Seconds you will receive photos</p>
                    </div>
                    <div className='rounded-[16px] bg-[#F6F6F6] py-[16px] px-[9px] shrink-0 h-[210px] flex flex-col justify-between'>
                        <div>
                            <img src={guidelinesWhatsapp} alt="" />
                            <p className='font_semibold_16  mt-[16px]'>WhatsApp</p>
                        </div>
                        <p className='font_semibold_18 max-w-[185px]'>We will send your sorted photo gallery on WhatsApp</p>
                    </div>
                    <div className='rounded-[16px] bg-[#F6F6F6] py-[16px] px-[9px] shrink-0 h-[210px] flex flex-col justify-between'>
                        <div>
                            <img src={guidelinesGmail} alt="" />
                            <p className='font_semibold_16  mt-[16px]'>Gmail</p>
                        </div>
                        <p className='font_semibold_18 max-w-[185px]'>You will receive the mail with sorted photos</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GuidlinesDemo