import React from "react";
import hamburgerIcon from "../Assets/Images/hamburgerIcon.svg";
import { useNavigate } from "react-router-dom";
import Hamburger from "./Hamburger";

function Navbar() {
  const navigate = useNavigate();

  return (
    <div className=" px-[5.26%] xl:px-[120px] pt-[60px] pb-[40px] xl:py-[50px] flex items-center justify-between">
      <div className="flex gap-[16px]">
        <div className="mobileOnly">
          <Hamburger />
        </div>
        <div
          onClick={() => navigate("/")}
          className="text-[#161616] cursor-pointer"
        >
          <p className="font-bold text-[20px] xl:text-[22px]">
            SHOOT<span className="font-normal">MATES</span>
          </p>
        </div>
      </div>
      <div className="text-[#161616] text-[16px] flex justify-between w-full px-[10.56%] desktopOnly">
        {/* <button>Contest/Events</button> */}
        <button>Tools</button>
        {/* <button>Albums</button>
        <button>CRM</button>
        <button>Tutorials</button> */}
        {/* <button onClick={() => navigate("/pricing")}>Pricing</button> */}
      </div>
      <div>
        <button className="min-w-[87px] px-[16px] py-[8px] rounded-[30px] bg-[#000000] text-[13px] leading-[18px] text-[#F6F6F6]">
          Join now
        </button>
      </div>
    </div>
  );
}

export default Navbar;
