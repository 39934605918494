import React from "react";

function StudioFAQ({ data }) {
  return (
    <div>
      {data?.map((obj) => (
        <div className="mb-[30px]">
          <p className="mb-[10px] text600 text-[14px] md:text-[16px]">
            {obj?.question}
          </p>
          <pre className="text400 text-[14px] md:text-[16px]">
            {obj?.answer}
          </pre>
        </div>
      ))}
    </div>
  );
}

export default StudioFAQ;
