import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import NavbarNew from "../Components/NavbarNew";
import axios from "axios";
import Footer from "../Components/Footer";
import NavbarBottom from "../Components/NavbarBottom";
import { pricingDatas } from "../constants/Constants";
import PricingComponent from "../Components/pricing/PricingComponent";
import styles from "./Pricing.module.css";

function Pricing() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://storage.googleapis.com/shootmatescrmdatas/pricingShootmates.json`
      );
      setData(response?.data?.packages);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div>
      <NavbarNew />
      <div className="w-full h-full min-h-[70vh] px-[5.26%] xl:px-[8.33%] mb-[50px] xl:mb-[100px]">
        <div className="w-full flex flex-col justify-center items-center text-center">
          <p className="mt-[16px] lg:mt-[30px] font_semibold_40_30 text-[#161616]">
            We’ve got a pricing plan that's perfect for you.
          </p>
          <p className="mt-[16px] lg:mt-[24px] font_normal_21_16 text-[#161616] px-[50px] lg:px-0">
            Pricing plans for businesses at every stage of growth.
          </p>
        </div>
        <FaceDetection
          name={"Photo Delivey"}
          priceDetail={data?.FaceDetection}
        />
        <MemshotsToolsBullets
          title={"Signature"}
          name={"Album"}
          priceDetail={data?.SignatureAlbum}
        />
        <MemshotsToolsBullets
          title={"Photo"}
          name={"Selection"}
          priceDetail={data?.SelectionTool}
        />
      </div>
      <Footer />
      <NavbarBottom />
    </div>
  );
}

export default Pricing;

function MemshotsToolsBullets(props) {
  return (
    <div>
      <div className="text-left mt-[40px] lg:hidden ">
        <p className="font_semibold_24 text-[#000]">
          <span className="font_semibold_32 text-[#3171F6]">
            {" "}
            {props.title}{" "}
          </span>{" "}
          <br /> {props.name}
        </p>
      </div>
      <div className={`${styles.packageSection} no-scrollbar`}>
        <div className="hidden lg:block text-left   row-span-2">
          <p className="font_semibold_24 text-[#000]">
            <span className="font_semibold_32 text-[#3171F6]">
              {" "}
              {props.title}{" "}
            </span>{" "}
            <br /> {props.name}
          </p>
        </div>
        {props.priceDetail?.map((obj, i) => (
          <div className={styles.cards} key={i}>
            <div className={styles.cardsHeading}>
              <p>{obj?.heading}</p>
            </div>
            <div
              style={{
                background: obj?.priceBgColor,
              }}
              className={styles.cardsPrice}
            >
              <p>{obj?.price}</p>
            </div>
            <div className={styles.cardsDescription}>
              <p className="font_semibold_16 text-[#161616]">{obj?.text}</p>
              {obj?.points ? (
                <div className="px-[20px] mt-[8px]">
                  {obj?.points?.map((item) => (
                    <ul style={{ listStyleType: "disc" }}>
                      <li>{item}</li>
                    </ul>
                  ))}
                </div>
              ) : (
                <div className="mt-[8px]">
                  <p>{obj?.contact}</p>
                </div>
              )}
            </div>
          </div>
        ))}
        <div className="mt-[32px] lg:mt-[40px]  col-span-3 hidden lg:block">
          <div className={styles.buyNowSection}>
            <p className="font_bold_24">Ready to dive in?</p>
            <a href="https://crm.memshots.com" target="_blank" rel="noreferrer">
              <button
                className={`${styles.tryDemoButton} flex items-center gap-[8px]`}
              >
                Buy Now
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="mt-[32px] lg:mt-[40px]  block lg:hidden">
        <div className={styles.buyNowSection}>
          <p className="font_bold_24">Ready to Dive in?</p>
          <a href="https://crm.memshots.com" target="_blank" rel="noreferrer">
            <button
              className={`${styles.tryDemoButton} flex items-center gap-[8px]`}
            >
              Buy Now
            </button>
          </a>
        </div>
      </div>
      <div className="w-full horizontalSeperator mt-[30px] lg:mt-[60px]"></div>
    </div>
  );
}

function FaceDetection(props) {
  return (
    <div>
      <div className="text-left mt-[40px] lg:hidden ">
        <p className="font_semibold_24 text-[#000]">
          <span className="font_semibold_32 text-[#3171F6]">AI</span> <br />{" "}
          {props.name}
        </p>
      </div>
      <div className={`${styles.packageSection} no-scrollbar`}>
        <div className="hidden lg:block text-left  row-span-2">
          <p className="font_semibold_24 text-[#000]">
            <span className="font_semibold_32 text-[#3171F6]"> AI </span> <br />{" "}
            {props.name}
          </p>
        </div>
        {props.priceDetail?.map((obj, i) => (
          <FaceDetectionData data={obj} />
        ))}
        <div className="mt-[32px] lg:mt-[40px]  col-span-3 hidden lg:block">
          <div className={styles.buyNowSection}>
            <p className="font_bold_24">Ready to dive in?</p>
            <a href="https://crm.memshots.com" target="_blank" rel="noreferrer">
              <button
                className={`${styles.tryDemoButton} flex items-center gap-[8px]`}
              >
                Buy Now
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="mt-[32px] lg:mt-[40px] block lg:hidden">
        <div className={styles.buyNowSection}>
          <p className="font_bold_24">Ready to dive in?</p>
          <a href="https://crm.memshots.com" target="_blank" rel="noreferrer">
            <button
              className={`${styles.tryDemoButton} flex items-center gap-[8px]`}
            >
              Buy Now
            </button>
          </a>
        </div>
      </div>
      <div className="w-full horizontalSeperator mt-[30px] lg:mt-[60px]"></div>
    </div>
  );
}

function FaceDetectionData(props) {
  const [filterSelected, setFilterSelected] = useState("three");
  return (
    <div className={styles.cards}>
      <div className={styles.cardsHeading}>
        <p>{props?.data?.heading}</p>
      </div>
      <div className="py-[15px] flex justify-center">
        <div className={styles.filterFaceDetection}>
          <div
            onClick={() => setFilterSelected("three")}
            style={{
              background: filterSelected === "three" ? "#17064A" : "#EDEDED",
              color: filterSelected === "three" ? "#fff" : "#161616",
            }}
            className={styles.filterChip}
          >
            3 Month
          </div>
          <div
            onClick={() => setFilterSelected("one")}
            style={{
              background: filterSelected === "one" ? "#17064A" : "#EDEDED",
              color: filterSelected === "one" ? "#fff" : "#161616",
            }}
            className={styles.filterChip}
          >
            1 Year
          </div>
        </div>
      </div>
      <div
        style={{
          background: props?.data?.priceBgColor,
        }}
        className={styles.cardsPrice}
      >
        <p>
          {filterSelected === "three"
            ? props?.data?.threeMonthPrice
            : props?.data?.oneYearPrice}
        </p>
      </div>
      <div className={styles.cardsDescription}>
        <p className="font_semibold_16 text-[#161616]">
          {filterSelected === "three"
            ? props?.data?.threeMonthText
            : props?.data?.oneYearText}
        </p>
        {props?.data?.points ? (
          <div className="px-[20px] mt-[8px]">
            {props?.data?.points?.map((item) => (
              <ul style={{ listStyleType: "disc" }}>
                <li>{item}</li>
              </ul>
            ))}
          </div>
        ) : (
          <div>
            <p className="font_semibold_16 text-[#161616]">
              {props?.data?.text}
            </p>
            <p className="mt-[8px]">{props?.data?.contact}</p>
          </div>
        )}
      </div>
    </div>
  );
}
