import React from "react";
import locationIcon from "../../Assets/Images/studioLocation.svg";
import styles from "../styles/home.module.css";
import { Link } from "react-router-dom";

function StudioShowcase({ studioData }) {
  return (
    <div className="grid xl:grid-cols-2 gap-[10px] mb-[30px] w-full">
      {studioData?.showcase?.map((obj, index) => {
        return (
          <Link to={`${index}`}>
            <div className="relative w-full cursor-pointer transition-all duration-400 ease-in-out hover:opacity-90 hover:scale-[0.99]">
              <img
                src={obj?.coverImage}
                alt=""
                className="md:w-full md:h-[285px] w-full h-[196px] object-cover rounded-[8px] md:rounded-[2px]"
              />
              <div
                className="absolute inset-0 w-full h-full z-10 rounded-[8px] md:rounded-[2px]"
                style={{
                  background:
                    "linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%)",
                }}
              />

              <div className="absolute inset-0 w-full h-full justify-between flex flex-col items-center text-center px-[22px] md:px-[72px] pb-[10px] md:pb-[16px] z-20">
                <div></div>
                <div className="text-white w-full">
                  <p
                    className={`${styles.dmSerifFont} mb-[4px] md:mb-[6px] text-[16px] md:text-[22px] leading-[140%]`}
                  >
                    {obj?.title}
                  </p>
                  <p
                    className={`${styles.elsieFont}  text-[8px] md:text-[12px] leading-[140%]`}
                  >
                    {obj?.subtitle}
                  </p>
                </div>
                <div className="flex gap-[2px] items-center text-white">
                  <img src={locationIcon} alt="" />
                  <p
                    className={`${styles.elsieFont} text-[8px] md:text-[12px] leading-[140%]`}
                  >
                    {obj?.location}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
}

export default StudioShowcase;
