import React, { useEffect } from 'react'
import Navbar from '../Components/Navbar'
import Cover from '../Components/AiPowered/Cover'
import EventPlanners from '../Components/AiPowered/EventPlanners'
import Photographer from '../Components/AiPowered/Photographer'
import Galleries from '../Components/AiPowered/Galleries'
import Album from '../Components/AiPowered/Album'
import Crm from '../Components/AiPowered/Crm'
import SelectionTool from '../Components/AiPowered/SelectionTool'
import Invitations from '../Components/AiPowered/Invitations'

function AiPowered() {

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <div>
            <Navbar />
            <Cover />
            <EventPlanners />
            <Photographer />
            <Galleries />
            <Album />
            <Crm />
            <SelectionTool />
            <Invitations />
        </div>
    )
}

export default AiPowered