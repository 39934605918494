import React, { useContext, useEffect, useState } from "react";
import Navbar from "../Components/NavbarNew";
import styles from "./Css/facedetection.module.css";
import GuidlinesDemo from "../Components/GuidlinesDemo";
import guidelinesPhotographerImage from "../Assets/Images/Demo/guidelinesPhotographerImage.jpg";
import { useDropzone } from "react-dropzone";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { ref, uploadBytesResumable, listAll, deleteObject } from "firebase/storage";
import { mediaStorage } from "../store/firebase-config";
import axios from "axios";
import Lottie from '../Assets/Animation/Loading'
import { userAuthContext } from "../Context/UserAuthContext";
import { PopupWrapper } from "../UI/PopupWrapper/PopupWrapper";
import ErrorPopup from "../Components/Popups/ErrorPopup/ErrorPopup";
import Toast from "../Components/ToastMessage/Toast";

function FaceDetectionDemoPhotographer() {
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  let { folderId } = useParams();
  const navigate = useNavigate();
  const tokenData = userIdToken || currentUser?.accessToken;
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedMedia, setUploadedMedias] = useState([]);
  const [toastMessages, setToastMessages] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);
  const [currentFileProgress, setCurrentFileProgress] = useState(0);
  const [folderName, setFolderName] = useState(null);
  const [totalFiles, setTotalFiles] = useState(0);
  const [completedFiles, setCompletedFiles] = useState(0);
  const [fileError, setFileError] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
    header: "",
  });
  const memshotAlbumId = parseInt(process.env.REACT_APP_MEMSHOTSALBUMID);
  const baseUrl = `https://storage.googleapis.com/albumdata/${memshotAlbumId}/${folderName}/`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await verifyUser();
        const data = await getSessionData(memshotAlbumId, folderId);
        setFolderName(data?.sections?.folderName);
        await getMediaNamesFromFirebase(
          memshotAlbumId,
          data?.sections?.folderName
        );
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (folderName) {
      getMediaNamesFromFirebase(memshotAlbumId, folderName);
    }
  }, [completedFiles]);

  const getMediaNamesFromFirebase = async (memshotAlbumId, folderName) => {
    try {
      const storageRef = ref(mediaStorage, `/${memshotAlbumId}/${folderName}`);
      const result = await listAll(storageRef);
      const mediaNames = result?.items
        .map((item) => item.name)
        .filter((name) => name !== "folderCheck.keep");
      setUploadedMedias(mediaNames);
    } catch (error) {
      console.error("Error fetching media names:", error);
      throw error;
    }
  };

  const getSessionData = async (memshotAlbumId, folderId) => {
    try {
      const config = {
        method: "get",
        url: `${process.env.REACT_APP_GET_SESSION_DATA}/${memshotAlbumId}/${folderId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      const dataReturn = response?.data?.data;
      return dataReturn;
    } catch (error) {
      console.error("Error fetching media names:", error);
      throw error;
    }
  };

  const startFilesUpload = async (filesToUpload) => {
    try {
      setFileError("");
      setIsUploading(true);
      setTotalFiles(filesToUpload?.length);
      setCompletedFiles(0);
      for (const file of filesToUpload) {
        setCurrentFile(file);
        const fileName = `${file?.name}`;
        const storageRef = ref(
          mediaStorage,
          `/${memshotAlbumId}/${folderName}/${fileName}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (
              (snapshot.bytesTransferred / snapshot.totalBytes) *
              100
            ).toFixed(2);
            setCurrentFileProgress(progress);
          },
          (error) => {
            console.error(`Error uploading ${fileName}:`, error);
            alert(`Error uploading ${fileName}. Please try again.`);
          },
          () => {
            setCurrentFile(null);
            setCurrentFileProgress(0);
          }
        );
        await uploadTask;
        setCompletedFiles((prev) => prev + 1);
      }
      setIsUploading(false);
    } catch (error) {
      console.error("Error uploading files to Firebase:", error);
      setIsUploading(false);
      alert("Error uploading files. Please try again.");
    }
  };

  const handleImageImport = async (acceptedFiles) => {
    try {
      const MAX_FILE_SIZE = 35 * 1024 * 1024;

      if (isUploading) {
        setToastMessages([
          "Please wait for the current upload task to complete..",
        ]);
        return false;
      }
      const [validFiles, invalidFiles] = _.partition(acceptedFiles, (file) => {
        const isImage =
          file.type.startsWith("image/jpeg") ||
          file.type.startsWith("image/jpg");
        const isSizeValid = file.size <= MAX_FILE_SIZE;
        return isImage && isSizeValid;
      });

      if (invalidFiles.length > 0) {
        setToastMessages(
          invalidFiles.map(
            (file) =>
              `${file.name} is an unsupported file type or exceeds the size limit 25mb`
          )
        );
      }

      if (validFiles.length < 1) {
        setToastMessages([
          "No Valid File Format Found or files exceed the size limit 25mb.",
        ]);
        return false;
      }

      if (validFiles.length > 10) {
        setToastMessages([
          "Only 10 images can be selected at a time. Skipping additional images.",
        ]);
        validFiles.splice(10);
      }

      let readyToUploadFiles = [];

      if (uploadedMedia.length !== 0) {
        readyToUploadFiles = _.differenceBy(
          validFiles,
          uploadedMedia.map((name) => ({ name })),
          "name"
        );
      } else {
        readyToUploadFiles = validFiles;
      }

      let skipCount = _.intersectionBy(
        validFiles,
        uploadedMedia.map((name) => ({ name })),
        "name"
      ).length;
      if (skipCount > 0) {
        setToastMessages([`${skipCount} file(s) already exist. Skipping...`]);
      }

      if (uploadedMedia.length >= 10) {
        setToastMessages([
          `Only 10 file(s) can  be uploaded in one folder for demo`,
        ]);
        return;
      }

      if (readyToUploadFiles.length + uploadedMedia.length >= 10) {
        skipCount = 10 - uploadedMedia.length;
        setToastMessages([
          `${skipCount} file(s) Skipping...Only 10 file(s) can be uploaded in one folder for demo`,
        ]);
        readyToUploadFiles.splice(skipCount);
      }

      if (readyToUploadFiles.length === 0) {
        setToastMessages([`No New file(s) to upload`]);
        return;
      }
      await startFilesUpload(readyToUploadFiles);
      return;
    } catch (error) {
      console.error("Error compressing one or more images:", error);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: false,
    noKeyboard: false,
    multiple: true,
    preventDropOnDocument: false,
    disabled: isUploading,
    onDrop: handleImageImport,
  });

  const handleDeleteImage = async (imageName, e) => {
    e.stopPropagation();
    try {
      const storageRef = ref(mediaStorage, `/${memshotAlbumId}/${folderName}/${imageName}`);
      await deleteObject(storageRef);
      setUploadedMedias(uploadedMedia.filter((media) => media !== imageName));
    } catch (error) {
      console.error(`Error deleting ${imageName}:`, error);
      alert(`Error deleting ${imageName}. Please try again.`);
    }
  };

  const handleSubmitToTraining = async () => {
    try {
      if (uploadedMedia.length < 5) {
        setFileError("Please upload at least 5 files for scanning");
        return;
      }
      verifyUser();
      setLoading(true);

      // First API call
      let url = `${process.env.REACT_APP_SEND_TO_SCANNING}`;
      let config = {
        method: "post",
        url: url,
        data: {
          folderId: folderId,
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);

      if (response.status === 200) {
        if (response?.data?.error) {
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setLoading(false);
        } else {
          // Second API call
          let secondConfig = {
            method: "post",
            url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesAlbums/api/shootmates/facetraining/updateImages/trainingDataDemo/${folderId}`,
            headers: {
              Authorization: `Bearer ${tokenData}`,
              "Content-Type": "application/json",
            },
          };
          const secondResponse = await axios(secondConfig);

          if (secondResponse.status === 200) {
            setError({
              errorMessage: secondResponse?.data?.msg,
              errorStatus: false,
              header: "Message",
            });
            setLoading(false);
            navigate(`/aiphotosortingdemo/${folderId}/scanning`);
          } else {
            setError({
              errorMessage: "Error occurred please try again!!",
              errorStatus: true,
              header: "Message",
            });
            setLoading(false);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Lottie />
        </>
      ) : (
        <div className="containFull bg-[#fff] ">
          <Navbar />
          {error.errorStatus === true && (
            <PopupWrapper visible={error.errorStatus}>
              <ErrorPopup error={error} setError={setError} />
            </PopupWrapper>
          )}
          <Toast messages={toastMessages} onClose={() => setToastMessages([])} />
          <div className="flex flex-col items-center px-[20px] lg:px-[80px]">
            <div className="w-full max-w-[1280px]">
              <div className="w-full py-[24px] lg:py-[18px] flex justify-between ">
                <div className={styles.attendeeButton}>Photographer</div>
                <p className="font_semibold_24 text-[#000] w-full text-center">
                  Experience the  AI Photo Delivery Demo
                </p>
                <div className="w-[110px] hidden lg:block"></div>
              </div>
              <div className="mt-[28px] lg:mt-[20px] flex justify-center">
                <div className={styles.multiplePhotosContainer}>
                  <div className=" absolute left-[-30px] w-[130px]  transform -rotate-45  bg-[#497FFF]  py-[3px]">
                    <p className="font_medium_13 text-[#F6F6F6] text-center">
                      Photographer
                    </p>
                  </div>
                  <p className="font_semibold_24_21">Upload your album</p>
                  <div className={styles.draganddropSection}>
                    <div
                      {...getRootProps()}
                      className={styles.draganddropSectionInner}
                    >
                      <input {...getInputProps()} />
                      {!_.isEmpty(uploadedMedia) ? (
                        <div className="w-full gap-[12px] grid grid-cols-3  sm:grid-cols-4 lg:grid-cols-6 ">
                          {uploadedMedia?.map((obj, i) => (
                            <div key={i} className="relative w-full h-full aspect-square group">
                              <img
                                src={`${baseUrl}${obj}`}
                                alt=""
                                className="w-full h-full object-cover cursor-pointer hover:opacity-[0.8]"
                              />
                              <button
                                className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded opacity-0 group-hover:opacity-100"
                                onClick={(e) => handleDeleteImage(obj, e)}
                              >
                                Delete
                              </button>
                            </div>
                          ))}
                        </div>
                      ) : isDragActive ? (
                        <p>Drop your photos here</p>
                      ) : (
                        <div className="flex flex-col items-center gap-[20px] text-center">
                          <p>Drag & Drop photos here</p>
                          <button className={styles.takeSelfieButton}>
                            Upload Photos
                          </button>
                        </div>
                      )}
                    </div>
                    {isUploading && (
                      <div className={styles.uploadingProgress}>
                        <p>Uploading: {currentFile?.name}</p>
                        <p>Progress: {currentFileProgress} %</p>
                        <p>
                          {completedFiles}/{totalFiles} Completed
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="w-full flex flex-col lg:flex-row gap-[16px] justify-between mt-[24px] items-center">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <button
                        style={{
                          width: "fit-content",
                        }}
                        className={styles.takeSelfieButton}
                      >
                        Upload more
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={handleSubmitToTraining}
                        style={{
                          width: "fit-content",
                        }}
                        className={styles.takeSelfieButton}
                      >
                        Start Face Training
                      </button>
                      {fileError && (
                        <p className="text-red-500 text-sm mt-1">{fileError}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <GuidlinesDemo image={guidelinesPhotographerImage} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FaceDetectionDemoPhotographer;
